<template>
  <v-btn
      :outlined="true"
      :loading="loading"
      :disabled="loading"
      color="success"
      @click="init"
      v-bind="$attrs"
      v-on="$listeners"
  >
    <v-icon>
      mdi-plus
    </v-icon>

    <slot/>

    <v-dialog
        v-model="dialog"
        :persistent="true"
        :scrollable="true"
        max-width="1000"
    >
      <v-card>
        <v-card-title>
          Vytvoriť udalosť

          <v-spacer/>

          <v-btn
              :icon="true"
              color="default"
              @click="closeDialog"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-form
              @submit.stop.prevent="submit"
          >
            <v-row>
              <v-col
                  cols="12"
              >
                <SuggestOrganizers
                    v-model="EventForm.Organizer"
                    :error-messages="errors.Organizer"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Organizátor"
                    :createButton="true"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="6"
              >
                <ImageFiles
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Obrázok"
                    :persistent-hint="true"
                    hint="Odporúčaný formát 4:3"
                    :File="EventForm.File"
                    :errorsFile="errors.File"
                    @setFile="setFile"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="6"
              >
                <v-text-field
                    v-model="EventForm.title"
                    :error-messages="errors.title"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Názov"
                />
              </v-col>

              <v-col
                  cols="12"
              >
                <EditorPartials
                    v-model="EventForm.about"
                    label="..."
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="6"
              >
                <v-textarea
                    v-model="EventForm.description"
                    :error-messages="errors.description"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Popis"
                    :rows="2"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="6"
              >
                <v-textarea
                    v-model="EventForm.keywords"
                    :error-messages="errors.keywords"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Kľúčové slová"
                    :rows="2"
                    :persistent-hint="true"
                    hint="V prípade, že viac tak: ,"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="4"
              >
                <SuggestCountries
                    v-model="Country"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Krajina"
                    :createButton="true"
                    @change="() => EventForm.Region = null"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="4"
              >
                <SuggestRegions
                    v-model="EventForm.Region"
                    :error-messages="errors.Region"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Kraj"
                    :createButton="true"
                    :support="(regions) => regions.filter((Region) => (
                      Country
                        ? Region.Country.id === Country
                        : false
                    ))"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="4"
              >
                <v-text-field
                    v-model="EventForm.address"
                    :error-messages="errors.address"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Adresa"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="4"
              >
                <v-select
                    v-model="EventForm.action"
                    :error-messages="errors.action"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Akcia"
                    :items="$store.getters['register/getEventAction']"
                    no-data-text="Žiadne výsledky"
                />
              </v-col>

              <template
                  v-if="EventForm.action === $store.state.register.Event.action['one-day'].value"
              >
                <v-col
                    cols="12"
                    lg="4"
                >
                  <v-text-field
                      v-model="EventForm.oneDayAt"
                      :error-messages="errors.oneDayAt"
                      :filled="true"
                      :dense="true"
                      label="Dátum a čas akcie"
                      type="datetime-local"
                      hide-details="auto"
                  />
                </v-col>

                <v-col
                    cols="12"
                    lg="4"
                />
              </template>

              <template
                  v-if="EventForm.action === $store.state.register.Event.action['more-days'].value"
              >
                <v-col
                    cols="12"
                    lg="4"
                >
                  <v-text-field
                      v-model="EventForm.moreDaysFrom"
                      :error-messages="errors.moreDaysFrom"
                      :filled="true"
                      :dense="true"
                      hide-details="auto"
                      label="Dátum a čas akcie od"
                      type="datetime-local"
                  />
                </v-col>

                <v-col
                    cols="12"
                    lg="4"
                >
                  <v-text-field
                      v-model="EventForm.moreDaysTo"
                      :error-messages="errors.moreDaysTo"
                      :filled="true"
                      :dense="true"
                      hide-details="auto"
                      label="Dátum a čas akcie do"
                      type="datetime-local"
                  />
                </v-col>
              </template>

              <v-col
                  cols="12"
                  lg="4"
              >
                <v-text-field
                    v-model="EventForm.applicationTo"
                    :error-messages="errors.applicationTo"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Dátum a čas prihlášky do"
                    type="datetime-local"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="4"
              >
                <v-select
                    v-model="EventForm.type"
                    :error-messages="errors.type"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Typ"
                    :items="$store.getters['register/getEventType']"
                    no-data-text="Žiadne výsledky"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="4"
              >
                <v-text-field
                    v-if="EventForm.type === $store.state.register.Event.type['courses'].value"
                    v-model="EventForm.coursePrice"
                    :error-messages="errors.coursePrice"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Cena kurzu"
                    type="number"
                />
              </v-col>

              <v-col
                  cols="12"
              >
                <SuggestFiltersFilterGroups
                    v-model="EventForm.filters"
                    :error-messages="errors.filters"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    :multiple="true"
                    :clearable="true"
                    :createButton="true"
                />
              </v-col>

              <v-col
                  cols="12"
              >
                <SuggestOptionsOptionGroups
                    v-model="EventForm.options"
                    :error-messages="errors.options"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    :multiple="true"
                    :clearable="true"
                    :createButton="true"
                />
              </v-col>

              <v-col
                  cols="12"
              >
                <v-btn
                    type="submit"
                    :rounded="true"
                    :outlined="true"
                    :loading="loading"
                    :disabled="loading"
                    color="success"
                >
                  <v-icon>
                    mdi-plus
                  </v-icon>

                  Vytvoriť
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-btn>
</template>

<script>
  import Events from '@/services/events'

  import ImageFiles from '@/components/Files/Image'

  import EditorPartials from '@/components/_Partials/Editor'

  import SuggestOrganizers from '@/components/Organizers/Suggest'
  import SuggestCountries from '@/components/Countries/Suggest'
  import SuggestRegions from '@/components/Regions/Suggest'
  import SuggestFiltersFilterGroups from '@/components/FilterGroups/SuggestFilters'
  import SuggestOptionsOptionGroups from '@/components/OptionGroups/SuggestOptions'

  export default {
    name: 'Create',
    components: {
      ImageFiles,
      EditorPartials,
      SuggestOrganizers,
      SuggestCountries,
      SuggestRegions,
      SuggestFiltersFilterGroups,
      SuggestOptionsOptionGroups
    },
    data() {
      return {
        loading: false,
        dialog: false,
        EventForm: {},
        errors: {},
        Country: null
      }
    },
    methods: {
      init() {
        this.loadData()
      },
      closeDialog() {
        this.dialog = false
      },
      loadData() {
        this.dialog = true

        this.EventForm = {
          Organizer: null,
          File: null,
          title: '',
          about: '',
          description: '',
          keywords: '',
          Region: null,
          address: '',
          action: this.$store.state.register.Event.action['one-day'].value,
          oneDayAt: '',
          moreDaysFrom: '',
          moreDaysTo: '',
          applicationTo: '',
          type: this.$store.state.register.Event.type['volunteering'].value,
          coursePrice: '',
          filters: [],
          options: []
        }

        this.errors = {}
      },
      setFile(File) {
        this.EventForm.File = File
      },
      async submit() {
        this.loading = true

        await Events
          .create({
            data: this.EventForm
          })
          .then((response) => {
            this.$store.dispatch('layout/openSnackbar', {
              message: response.data.message
            })

            this.closeDialog()

            this.$emit('init')
          })
          .catch((error) => {
            switch (error.response.status) {
              case 400:
                this.errors = error.response.data.errors

                break

              case 401:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                this.closeDialog()

                break
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
</script>
