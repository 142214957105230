<template>
  <v-card
      v-if="content && FilterGroup"
  >
    <v-card-title>
      <span
          class="text-truncate"
          style="max-width: 900px"
      >
        {{ FilterGroup.title }}
      </span>

      <v-spacer/>

      <v-btn
          :icon="true"
          :outlined="true"
          color="primary"
          to="/filter-groups"
      >
        <v-icon>
          mdi-reply
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col
            cols="12"
        >
          <Filters
              :FilterGroup="FilterGroup"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>

  <v-progress-circular
      v-else
      :indeterminate="true"
  />
</template>

<script>
  import FilterGroups from '@/services/filter-groups'

  import Filters from '@/components/FilterGroup/Filters'

  export default {
    name: 'FilterGroup',
    components: {
      Filters
    },
    data() {
      return {
        content: false,
        loading: false,
        FilterGroup: null
      }
    },
    metaInfo() {
      return {
        title: (
          this.FilterGroup
            ? this.FilterGroup.title
            : ''
        )
      }
    },
    mounted() {
      this.init()
    },
    watch: {
      $route(to, from) {
        if (to.path !== from.path) {
          this.init()
        }
      }
    },
    methods: {
      init() {
        this.loadData()
      },
      refresh() {
        this.loadData('loading', true)
      },
      setBreadcrumbs() {
        this.$store.dispatch('layout/setBreadcrumbs', {
          breadcrumbs: [
            {
              text: 'Skupiny filtrov',
              to: '/filter-groups'
            }
          ]
        })
      },
      loadData(key = 'content', value = false) {
        this[key] = value

        FilterGroups
          .read({
            id: this.$route.params.id
          })
          .then((response) => {
            this.setBreadcrumbs()

            this.$store.dispatch('layout/addBreadcrumbs', {
              breadcrumbs: [
                {
                  text: response.data.data.FilterGroup.title,
                  to: '/filter-group/' + response.data.data.FilterGroup.id
                }
              ]
            })

            this.FilterGroup = response.data.data.FilterGroup
          })
          .catch((error) => {
            switch (error.response.status) {
              case 401:
              case 404:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                this.$router.replace('/filter-groups')

                break
            }
          })
          .finally(() => {
            this[key] = !value
          })
      }
    }
  }
</script>
