<template>
  <v-row>
    <v-col
        cols="12"
    >
      <template
          v-if="$store.state.auth.isGranted"
      >
        ...
      </template>

      <template
          v-else
      >
        ...
      </template>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'Home',
    metaInfo() {
      return {
        title: 'Domov'
      }
    },
    mounted() {
      this.init()
    },
    methods: {
      init() {
        this.loadData()
      },
      setBreadcrumbs() {
        this.$store.dispatch('layout/setBreadcrumbs', {
          breadcrumbs: [
            {
              text: 'Domov',
              to: '/home'
            }
          ]
        })
      },
      loadData() {
        this.setBreadcrumbs()
      }
    }
  }
</script>
