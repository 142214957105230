<template>
  <v-app
      v-if="content"
  >
    <v-app-bar
        :app="true"
        :fixed="true"
        :dark="true"
        class="custom-app-bar"
    >
      <v-app-bar-nav-icon
          @click="$store.dispatch('layout/toggleDrawer')"
      />

      <v-app-bar-title>
        <v-img
            :src="logo"
            alt="BeeVolunteer.sk"
            max-width="120"
        />
      </v-app-bar-title>

      <template
          v-if="$store.state.auth.isGranted"
      >
        <v-spacer/>

        <ClearCache
            :icon="true"
        />
      </template>
    </v-app-bar>

    <v-navigation-drawer
        v-model="$store.state.layout.drawer"
        :app="true"
        :fixed="true"
        :dark="true"
        class="custom-navigation-drawer"
    >
      <v-list
          :light="true"
          class="custom-list-user"
      >
        <v-list-item>
          <template
              v-if="$store.state.auth.isGranted"
          >
            <v-list-item-content>
              <v-list-item-title>
                {{ $store.state.auth.User.name }}
              </v-list-item-title>

              <v-list-item-subtitle>
                {{ $store.state.auth.User.position }}
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <Logout
                  :icon="true"
              />
            </v-list-item-action>
          </template>

          <template
              v-else
          >
            <v-list-item-content>
              <v-list-item-title>
                Neprihlásený
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn
                  :icon="true"
                  :outlined="true"
                  color="primary"
                  to="/login"
              >
                <v-icon>
                  mdi-login
                </v-icon>
              </v-btn>
            </v-list-item-action>
          </template>
        </v-list-item>
      </v-list>

      <v-divider/>

      <v-list
          class="custom-list-menu"
      >
        <template
            v-for="(item, i) in items"
        >
          <v-list-item
              v-if="!item.subitems"
              :key="i"
              :to=item.to
              :ripple="false"
          >
            <v-list-item-icon>
              <v-icon>
                {{ item.icon }}
              </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-group
              v-else
              :key="i"
              :value="item.value"
              :ripple="false"
          >
            <template
                v-slot:activator
            >
              <v-list-item-icon>
                <v-icon>
                  {{ item.icon }}
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item-content>
            </template>

            <template
                v-for="(subitem, j) in item.subitems"
            >
              <v-list-item
                  :key="j"
                  :to=subitem.to
                  :ripple="false"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    {{ subitem.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list-group>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <v-container
          :fluid="true"
      >
        <v-breadcrumbs
            v-if="$store.state.layout.breadcrumbs.length > 0"
            divider="/"
            :items="$store.state.layout.breadcrumbs"
        />

        <router-view/>
      </v-container>
    </v-main>

    <v-snackbar
        v-model="$store.state.layout.snackbar"
        :app="true"
        :color="$store.state.layout.message.color"
    >
      {{ $store.state.layout.message.content }}
    </v-snackbar>

    <v-footer
        :app="true"
        :dark="true"
        class="custom-footer"
    >
      @ 2023

      <v-spacer/>

      v{{ version() }}

      <v-spacer/>

      <a
          href="https://fuudobre.sk/"
          target="_blank"
      >
        <v-img
            src="https://api.fuudobre.sk/assets/img/copyright_white.svg"
            alt="FúúDobre"
        />
      </a>
    </v-footer>
  </v-app>

  <v-progress-circular
      v-else
      :indeterminate="true"
  />
</template>

<script>
  import logo from '@/assets/img/logo.svg'

  import menu from '@/plugins/config/menu'

  import ClearCache from '@/components/ClearCache'
  import Logout from '@/components/Logout'

  export default {
    name: 'App',
    components: {
      ClearCache,
      Logout
    },
    data() {
      return {
        content: false,
        loading: false,
        logo: logo
      }
    },
    metaInfo() {
      return {
        titleTemplate: 'BeeVolunteer.sk - Systém | %s',
        meta: [
          {
            charset: 'utf-8'
          },
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1'
          }
        ]
      }
    },
    mounted() {
      this.init()
    },
    computed: {
      items() {
        if (this.$store.state.auth.isGranted) {
          return menu.items[this.$store.state.auth.User.type]
        } else {
          return []
        }
      }
    },
    methods: {
      init() {
        this.loadData()
      },
      refresh() {
        this.loadData('loading', true)
      },
      loadData(key = 'content', value = false) {
        this[key] = value

        this.$store.dispatch('register/callIndex')
          .finally(() => {
            this[key] = !value
          })
      },
      version() {
        return process.env.VUE_APP_VERSION
      }
    }
  }
</script>
