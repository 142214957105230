<template>
  <v-card
      class="grey lighten-3"
  >
    <v-card-title>
      Prihlášky ({{ count }})

      <v-spacer/>

      <v-btn
          :icon="true"
          :outlined="true"
          :loading="loading"
          :disabled="loading"
          color="default"
          @click="init"
      >
        <v-icon>
          mdi-refresh
        </v-icon>
      </v-btn>

      &nbsp;

      <v-btn
          :icon="true"
          :outlined="true"
          color="default"
          @click="windowOpen(loadLinkByPath('app/applications-by-event/export/' + Event.id))"
      >
        <v-icon>
          mdi-download
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col
            cols="12"
        >
          <v-expansion-panels
              v-model="expansionPanelsFilter"
              :multiple="true"
          >
            <v-expansion-panel>
              <v-expansion-panel-header>
                <span>
                  <v-icon
                      color="info"
                  >
                    mdi-filter
                  </v-icon>

                  Filtre
                </span>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-row>
                  <v-col
                      cols="12"
                      lg="3"
                  >
                    <v-text-field
                        v-model="params.Filters.createdAtFrom"
                        :error-messages="errors.Filters && errors.Filters.createdAtFrom"
                        :outlined="true"
                        :dense="true"
                        hide-details="auto"
                        label="Dátum podania od"
                        type="date"
                        :clearable="true"
                        @change="setFilter"
                    />
                  </v-col>

                  <v-col
                      cols="12"
                      lg="3"
                  >
                    <v-text-field
                        v-model="params.Filters.createdAtTo"
                        :error-messages="errors.Filters && errors.Filters.createdAtTo"
                        :outlined="true"
                        :dense="true"
                        hide-details="auto"
                        label="Dátum podania do"
                        type="date"
                        :clearable="true"
                        @change="setFilter"
                    />
                  </v-col>

                  <v-col
                      cols="12"
                      lg="3"
                  >
                    <v-text-field
                        v-model="params.Filters.updatedAtFrom"
                        :error-messages="errors.Filters && errors.Filters.updatedAtFrom"
                        :outlined="true"
                        :dense="true"
                        hide-details="auto"
                        label="Dátum rozhodnutia od"
                        type="date"
                        :clearable="true"
                        @change="setFilter"
                    />
                  </v-col>

                  <v-col
                      cols="12"
                      lg="3"
                  >
                    <v-text-field
                        v-model="params.Filters.updatedAtTo"
                        :error-messages="errors.Filters && errors.Filters.updatedAtTo"
                        :outlined="true"
                        :dense="true"
                        hide-details="auto"
                        label="Dátum rozhodnutia do"
                        type="date"
                        :clearable="true"
                        @change="setFilter"
                    />
                  </v-col>

                  <v-col
                      cols="12"
                      lg="3"
                  >
                    <v-select
                        v-model="params.Filters.status"
                        :error-messages="errors.Filters && errors.Filters.status"
                        :outlined="true"
                        :dense="true"
                        hide-details="auto"
                        label="Stav"
                        :items="$store.getters['register/getApplicationType']"
                        no-data-text="Žiadne výsledky"
                        :clearable="true"
                        @change="setFilter"
                    />
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>

      <v-row>
        <v-col
            cols="12"
        >
          <v-card>
            <v-card-text>
              <v-progress-linear
                  v-if="loading"
                  :indeterminate="true"
              />

              <v-simple-table>
                <template
                    v-slot:default
                >
                  <thead>
                    <tr>
                      <th>
                        <a
                            @click="setOrder($store.state.register.Application.Filter.order['createdAt'].value)"
                        >
                          <template
                              v-if="params.order === $store.state.register.Application.Filter.order['createdAt'].value"
                          >
                            <template
                                v-if="params.by === $store.state.register.Application.Filter.by['ASC'].value"
                            >
                              <v-icon>
                                mdi-chevron-up
                              </v-icon>
                            </template>

                            <template
                                v-else
                            >
                              <v-icon>
                                mdi-chevron-down
                              </v-icon>
                            </template>
                          </template>

                          Dátum a čas podania
                        </a>
                      </th>

                      <th>
                        <a
                            @click="setOrder($store.state.register.Application.Filter.order['updatedAt'].value)"
                        >
                          <template
                              v-if="params.order === $store.state.register.Application.Filter.order['updatedAt'].value"
                          >
                            <template
                                v-if="params.by === $store.state.register.Application.Filter.by['ASC'].value"
                            >
                              <v-icon>
                                mdi-chevron-up
                              </v-icon>
                            </template>

                            <template
                                v-else
                            >
                              <v-icon>
                                mdi-chevron-down
                              </v-icon>
                            </template>
                          </template>

                          Dátum a čas rozhodnutia
                        </a>
                      </th>

                      <th>
                        Fotografia
                      </th>

                      <th
                          class="fixed"
                      >
                        Dobrovoľník
                      </th>

                      <th>
                        Vek
                      </th>

                      <th>
                        Mesto
                      </th>

                      <th>
                        Stav
                      </th>

                      <th
                          class="text-right fixed"
                      />
                    </tr>
                  </thead>

                  <tbody>
                    <template
                        v-if="applications.length > 0"
                    >
                      <tr
                          v-for="Application in applications"
                          :key="Application.id"
                      >
                        <td>
                          {{ new Date(Application.createdAt)|dateFormat('DD.MM.YYYY HH:mm') }}
                        </td>

                        <td>
                          <template
                              v-if="Application.updatedAt"
                          >
                            {{ new Date(Application.updatedAt)|dateFormat('DD.MM.YYYY HH:mm') }}
                          </template>

                          <template
                              v-else
                          >
                            Nevyplnené
                          </template>
                        </td>

                        <td>
                          <silent-box
                              v-if="Application.Volunteer.Photo"
                              :image="{
                                  src: loadLinkByPath('files/generate/image/original/' + Application.Volunteer.Photo.token)
                              }"
                          >
                            <template
                                v-slot:silentbox-item
                            >
                              <v-img
                                  :src="loadLinkByPath('files/generate/image/thumbnail/36/36/' + Application.Volunteer.Photo.token)"
                                  alt="Obrázok"
                                  max-width="36px"
                              />
                            </template>
                          </silent-box>

                          <template
                              v-else
                          >
                            <v-icon
                                color="error"
                            >
                              mdi-close
                            </v-icon>
                          </template>
                        </td>

                        <td>
                          <span
                              class="text-truncate"
                              style="max-width: 300px"
                          >
                            {{ Application.Volunteer.name }}
                          </span>
                        </td>

                        <td>
                          {{ Application.Volunteer.age }}
                        </td>

                        <td>
                          <span
                              class="text-truncate"
                              style="max-width: 300px"
                          >
                            {{ Application.Volunteer.city }}
                          </span>
                        </td>

                        <td>
                          {{ $store.state.register.Application.status[Application.status].text }}
                        </td>

                        <td
                            class="text-right fixed"
                        >
                          <Preview
                              :icon="true"
                              :id="Application.id"
                              @refresh="refresh"
                              @init="init"
                          />

                          &nbsp;

                          <Update
                              :icon="true"
                              :id="Application.id"
                              @refresh="refresh"
                              @init="init"
                          />

                          &nbsp;

                          <Delete
                              :icon="true"
                              :id="Application.id"
                              @refresh="refresh"
                              @init="init"
                          />
                        </td>
                      </tr>
                    </template>

                    <template
                        v-else
                    >
                      <tr>
                        <td
                            :colspan="8"
                            class="text-center"
                        >
                          Nenašli sa žiadne prihlášky.
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row
          v-if="pages !== 0"
          align="center"
      >
        <v-col
            cols="4"
        >
          <a
              v-for="(limit, i) in $store.getters['register/getApplicationFilterLimit']"
              :key="i"
              @click="setLimit(limit.value)"
          >
            {{ limit.text }}
          </a>
        </v-col>

        <v-spacer/>

        <v-col
            cols="auto"
        >
          <v-btn
              :icon="true"
              :outlined="true"
              :loading="loading"
              :disabled="params.page === 1 || loading"
              color="primary"
              @click="previousPage"
          >
            <v-icon>
              mdi-chevron-left
            </v-icon>
          </v-btn>
        </v-col>

        <v-col
            cols="auto"
        >
          {{ params.page }} / {{ pages }}
        </v-col>

        <v-col
            cols="auto"
        >
          <v-btn
              :icon="true"
              :outlined="true"
              :loading="loading"
              :disabled="params.page === pages || loading"
              color="primary"
              @click="nextPage"
          >
            <v-icon>
              mdi-chevron-right
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  import Qs from 'qs'
  import QueryTypes from 'query-types'

  import Applications from '@/services/applications'

  import Preview from '@/components/Applications/Preview'
  import Update from '@/components/Applications/Update'
  import Delete from '@/components/Applications/Delete'

  export default {
    name: 'Applications',
    props: [
      'Event'
    ],
    components: {
      Preview,
      Update,
      Delete
    },
    data() {
      return {
        expansionPanelsFilter: [],
        loading: false,
        count: 0,
        pages: 1,
        applications: [],
        params: {
          Filters: {
            createdAtFrom: '',
            createdAtTo: '',
            updatedAtFrom: '',
            updatedAtTo: '',
            status: ''
          },
          order: this.$store.state.register.Application.Filter.order['createdAt'].value,
          by: this.$store.state.register.Application.Filter.by['DESC'].value,
          limit: this.$store.state.register.Application.Filter.limit[25].value,
          page: 1
        },
        errors: {}
      }
    },
    beforeMount() {
      this.beforeInit()
    },
    mounted() {
      this.init()
    },
    methods: {
      beforeInit() {
        this.params = {
          ...this.params,
          ...Qs.parse(QueryTypes.parseObject(this.$route.query))
        }
      },
      init() {
        this.loadData()
      },
      refresh() {
        setTimeout(() => {
          this.$emit('refresh')
        }, 1000)
      },
      setFilter() {
        this.params.page = 1

        this.init()
      },
      setOrder(order) {
        this.params.order = order

        switch (this.params.by) {
          case this.$store.state.register.Application.Filter.by['ASC'].value:
            this.params.by = this.$store.state.register.Application.Filter.by['DESC'].value

            break

          case this.$store.state.register.Application.Filter.by['DESC'].value:
            this.params.by = this.$store.state.register.Application.Filter.by['ASC'].value

            break
        }

        this.params.page = 1

        this.init()
      },
      setLimit(limit) {
        this.params.limit = limit

        this.params.page = 1

        this.init()
      },
      previousPage() {
        this.params.page -= 1

        window.scrollTo(0, 0)

        this.init()
      },
      nextPage() {
        this.params.page += 1

        window.scrollTo(0, 0)

        this.init()
      },
      loadData() {
        this.loading = true

        this.$router.replace('?' + Qs.stringify(this.params))

        Applications
          .indexEvent({
            eventId: this.Event.id,
            params: this.params
          })
          .then((response) => {
            this.count = response.data.data.count

            this.pages = response.data.data.pages

            this.applications = response.data.data.applications

            this.errors = {}
          })
          .catch((error) => {
            switch (error.response.status) {
              case 400:
                this.errors = error.response.data.errors

                break

              case 401:
              case 403:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                break
            }
          })
          .finally(() => {
            this.loading = false
          })
      },
      loadLinkByPath(path) {
        return process.env.VUE_APP_AXIOS_URL + path
      },
      windowOpen(url) {
        return window.open(url)
      }
    }
  }
</script>
