<template>
  <v-btn
      :outlined="true"
      :loading="loading"
      :disabled="loading"
      color="warning"
      @click="init"
      v-bind="$attrs"
      v-on="$listeners"
  >
    <v-icon>
      mdi-pencil
    </v-icon>

    <slot/>

    <v-dialog
        v-if="Article"
        v-model="dialog"
        :persistent="true"
        :scrollable="true"
        max-width="1000"
    >
      <v-card>
        <v-card-title>
          Upraviť článok

          <v-spacer/>

          <v-btn
              :icon="true"
              color="default"
              @click="closeDialog"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-form
              @submit.stop.prevent="submit"
          >
            <v-row>
              <v-col
                  cols="12"
                  lg="6"
              >
                <v-text-field
                    v-model="ArticleForm.publishedAt"
                    :error-messages="errors.publishedAt"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Dátum a čas publikovania"
                    type="datetime-local"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="6"
              >
                <v-text-field
                    v-model="ArticleForm.author"
                    :error-messages="errors.author"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Autor"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="6"
              >
                <ImageFiles
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Obrázok"
                    :persistent-hint="true"
                    hint="Odporúčaný formát 4:3"
                    :File="ArticleForm.File"
                    :errorsFile="errors.File"
                    @setFile="setFile"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="6"
              >
                <v-text-field
                    v-model="ArticleForm.title"
                    :error-messages="errors.title"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Názov"
                />
              </v-col>

              <v-col
                  cols="12"
              >
                <v-textarea
                    v-model="ArticleForm.perex"
                    :error-messages="errors.perex"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Úvodník"
                    :rows="4"
                />
              </v-col>

              <v-col
                  cols="12"
              >
                <EditorPartials
                    v-model="ArticleForm.about"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="6"
              >
                <v-textarea
                    v-model="ArticleForm.description"
                    :error-messages="errors.description"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Popis"
                    :rows="2"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="6"
              >
                <v-textarea
                    v-model="ArticleForm.keywords"
                    :error-messages="errors.keywords"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Kľúčové slová"
                    :rows="2"
                    :persistent-hint="true"
                    hint="V prípade, že viac tak: ,"
                />
              </v-col>

              <v-col
                  cols="12"
              >
                <v-btn
                    type="submit"
                    :rounded="true"
                    :outlined="true"
                    :loading="loading"
                    :disabled="loading"
                    color="warning"
                >
                  <v-icon>
                    mdi-pencil
                  </v-icon>

                  Upraviť
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-btn>
</template>

<script>
  import { dateFormat } from '@vuejs-community/vue-filter-date-format'

  import Articles from '@/services/articles'

  import ImageFiles from '@/components/Files/Image'

  import EditorPartials from '@/components/_Partials/Editor'

  export default {
    name: 'Update',
    props: [
      'id'
    ],
    components: {
      ImageFiles,
      EditorPartials
    },
    data() {
      return {
        loading: false,
        dialog: false,
        Article: null,
        ArticleForm: {},
        errors: {}
      }
    },
    methods: {
      init() {
        this.loadData()
      },
      closeDialog() {
        this.dialog = false
      },
      loadData() {
        this.dialog = false

        this.loading = true

        Articles
          .read({
            id: this.id
          })
          .then((response) => {
            this.dialog = true

            this.Article = response.data.data.Article

            this.ArticleForm = {
              publishedAt: dateFormat(new Date(response.data.data.Article.publishedAt), 'YYYY-MM-DDTHH:mm'),
              author: response.data.data.Article.author,
              File: (
                response.data.data.Article.File
                  ? response.data.data.Article.File.id
                  : null
              ),
              title: response.data.data.Article.title,
              perex: response.data.data.Article.perex,
              about: response.data.data.Article.about,
              description: response.data.data.Article.description,
              keywords: response.data.data.Article.keywords
            }

            this.errors = {}
          })
          .catch((error) => {
            switch (error.response.status) {
              case 401:
              case 404:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                this.closeDialog()

                break
            }
          })
          .finally(() => {
            this.loading = false
          })
      },
      setFile(File) {
        this.ArticleForm.File = File
      },
      async submit() {
        this.loading = true

        await Articles
          .update({
            id: this.id,
            data: this.ArticleForm
          })
          .then((response) => {
            this.$store.dispatch('layout/openSnackbar', {
              message: response.data.message
            })

            this.closeDialog()

            this.$emit('init')
          })
          .catch((error) => {
            switch (error.response.status) {
              case 400:
                this.errors = error.response.data.errors

                break

              case 401:
              case 404:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                this.closeDialog()

                break
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
</script>
