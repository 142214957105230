import Vue from 'vue'

import Qs from 'qs'

class Documents {
  async indexVolunteer({volunteerId, params}) {
    return await Vue.axios
      .get('app/documents-by-volunteer/' + volunteerId, {
        params,
        paramsSerializer: (params) => {
          return Qs.stringify(params)
        }
      })
  }

  async create({data}) {
    return await Vue.axios
      .post('app/documents', data)
  }

  async read({id}) {
    return await Vue.axios
      .get('app/documents/' + id)
  }

  async delete({id}) {
    return await Vue.axios
      .delete('app/documents/' + id)
  }
}

const documents = new Documents()

export default documents
