<template>
  <v-file-input
      :error-messages="errorMessages()"
      prepend-icon="mdi-file"
      :append-outer-icon="
        File
          ? 'mdi-delete'
          : ''
      "
      :loading="loading"
      :disabled="loading"
      @change="readData"
      @click:append-outer="
        File
          ? $emit('setFile', null)
          : ''
      "
      v-bind="$attrs"
      v-on="$listeners"
  />
</template>

<script>
  import Files from '@/services/files'

  export default {
    name: 'Pdf',
    props: [
      'File',
      'errorsFile'
    ],
    data() {
      return {
        loading: false,
        errors: {}
      }
    },
    methods: {
      readData(pdf) {
        if (pdf) {
          const fileReader = new FileReader()

          fileReader.onload = (event) => {
            const PdfForm = {
              name: pdf.name,
              size: pdf.size,
              type: pdf.type,
              content: event.target.result
            }

            this.uploadPdf(PdfForm)
          }

          fileReader.readAsDataURL(pdf)
        }
      },
      uploadPdf(PdfForm) {
        this.loading = true

        Files
          .uploadPdf({
            data: PdfForm
          })
          .then((response) => {
            this.$store.dispatch('layout/openSnackbar', {
              message: response.data.message
            })

            this.errors = {}

            this.$emit('setFile', response.data.data.id)
          })
          .catch((error) => {
            switch (error.response.status) {
              case 400:
                this.errors = error.response.data.errors

                break

              case 401:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                break
            }
          })
          .finally(() => {
            this.loading = false
          })
      },
      errorMessages() {
        let errorMessages = []

        if (this.errors.name) {
          errorMessages = errorMessages.concat(this.errors.name)
        }

        if (this.errors.size) {
          errorMessages = errorMessages.concat(this.errors.size)
        }

        if (this.errors.type) {
          errorMessages = errorMessages.concat(this.errors.type)
        }

        if (this.errors.content) {
          errorMessages = errorMessages.concat(this.errors.content)
        }

        if (this.errorsFile) {
          errorMessages = errorMessages.concat(this.errorsFile)
        }

        return errorMessages
      }
    }
  }
</script>
