const master = [
  {
    to: '/home',
    title: 'Nástenka',
    icon: 'mdi-home'
  },
  {
    title: 'Užívatelia',
    icon: 'mdi-account',
    value: false,
    subitems: [
      {
        to: '/admins',
        title: 'Administrátori'
      },
      {
        to: '/organizers',
        title: 'Organizátori'
      },
      {
        to: '/volunteers',
        title: 'Dobrovoľníci'
      }
    ]
  },
  {
    to: '/events',
    title: 'Udalosti',
    icon: 'mdi-calendar'
  },
  {
    title: 'Nastavenia',
    icon: 'mdi-cogs',
    value: false,
    subitems: [
      {
        to: '/countries',
        title: 'Krajiny'
      },
      {
        to: '/filter-groups',
        title: 'Skupiny filtrov'
      },
      {
        to: '/option-groups',
        title: 'Skupiny možností'
      },
      {
        to: '/index-groups',
        title: 'Skupiny číselníkov'
      }
    ]
  },
  {
    title: 'Obsah',
    icon: 'mdi-content-copy',
    value: false,
    subitems: [
      {
        to: '/sites',
        title: 'Stránky'
      },
      {
        to: '/articles',
        title: 'Články'
      },
      {
        to: '/reviews',
        title: 'Recenzie'
      },
      {
        to: '/files',
        title: 'Súbory'
      }
    ]
  }
]

const worker = [
  {
    to: '/home',
    title: 'Nástenka',
    icon: 'mdi-home'
  }
]

const items = {
  master,
  worker
}

const menu = {
  items
}

export default menu
