<template>
  <v-btn
      :outlined="true"
      :loading="loading"
      :disabled="loading"
      color="warning"
      @click="init"
      v-bind="$attrs"
      v-on="$listeners"
  >
    <v-icon>
      mdi-pencil
    </v-icon>

    <slot/>

    <v-dialog
        v-if="Site"
        v-model="dialog"
        :persistent="true"
        :scrollable="true"
        max-width="1000"
    >
      <v-card>
        <v-card-title>
          Upraviť stránku

          <v-spacer/>

          <v-btn
              :icon="true"
              color="default"
              @click="closeDialog"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-form
              @submit.stop.prevent="submit"
          >
            <v-row>
              <v-col
                  cols="12"
                  lg="6"
              >
                <v-text-field
                    v-model="SiteForm.title"
                    :error-messages="errors.title"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Názov"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="6"
              >
                <v-text-field
                    v-model="SiteForm.module"
                    :error-messages="errors.module"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Modul"
                />
              </v-col>

              <v-col
                  cols="12"
              >
                <EditorPartials
                    v-model="SiteForm.about"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="6"
              >
                <v-textarea
                    v-model="SiteForm.description"
                    :error-messages="errors.description"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Popis"
                    :rows="2"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="6"
              >
                <v-textarea
                    v-model="SiteForm.keywords"
                    :error-messages="errors.keywords"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Kľúčové slová"
                    :rows="2"
                    :persistent-hint="true"
                    hint="V prípade, že viac tak: ,"
                />
              </v-col>

              <v-col
                  cols="12"
              >
                <v-btn
                    type="submit"
                    :rounded="true"
                    :outlined="true"
                    :loading="loading"
                    :disabled="loading"
                    color="warning"
                >
                  <v-icon>
                    mdi-pencil
                  </v-icon>

                  Upraviť
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-btn>
</template>

<script>
  import Sites from '@/services/sites'

  import EditorPartials from '@/components/_Partials/Editor'

  export default {
    name: 'Update',
    props: [
      'id'
    ],
    components: {
      EditorPartials
    },
    data() {
      return {
        loading: false,
        dialog: false,
        Site: null,
        SiteForm: {},
        errors: {}
      }
    },
    methods: {
      init() {
        this.loadData()
      },
      closeDialog() {
        this.dialog = false
      },
      loadData() {
        this.dialog = false

        this.loading = true

        Sites
          .read({
            id: this.id
          })
          .then((response) => {
            this.dialog = true

            this.Site = response.data.data.Site

            this.SiteForm = {
              title: response.data.data.Site.title,
              module: response.data.data.Site.module,
              about: response.data.data.Site.about,
              description: response.data.data.Site.description,
              keywords: response.data.data.Site.keywords
            }

            this.errors = {}
          })
          .catch((error) => {
            switch (error.response.status) {
              case 401:
              case 404:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                this.closeDialog()

                break
            }
          })
          .finally(() => {
            this.loading = false
          })
      },
      async submit() {
        this.loading = true

        await Sites
          .update({
            id: this.id,
            data: this.SiteForm
          })
          .then((response) => {
            this.$store.dispatch('layout/openSnackbar', {
              message: response.data.message
            })

            this.closeDialog()

            this.$emit('init')
          })
          .catch((error) => {
            switch (error.response.status) {
              case 400:
                this.errors = error.response.data.errors

                break

              case 401:
              case 404:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                this.closeDialog()

                break
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
</script>
