import Vue from 'vue'

import Qs from 'qs'

class Indexes {
  async suggestModule({module}) {
    return await Vue.axios
      .get('app/indexes-by-module/suggest/' + module)
  }

  async indexIndexGroup({indexGroupId, params}) {
    return await Vue.axios
      .get('app/indexes-by-index-group/' + indexGroupId, {
        params,
        paramsSerializer: (params) => {
          return Qs.stringify(params)
        }
      })
  }

  async create({data}) {
    return await Vue.axios
      .post('app/indexes', data)
  }

  async read({id}) {
    return await Vue.axios
      .get('app/indexes/' + id)
  }

  async update({id, data}) {
    return await Vue.axios
      .put('app/indexes/' + id, data)
  }

  async delete({id}) {
    return await Vue.axios
      .delete('app/indexes/' + id)
  }
}

const indexes = new Indexes()

export default indexes
