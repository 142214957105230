<template>
  <v-card>
    <v-card-title>
      Skupiny číselníkov ({{ count }})

      <v-spacer/>

      <Create
          :icon="true"
          @init="init"
      />

      &nbsp;

      <v-btn
          :icon="true"
          :outlined="true"
          :loading="loading"
          :disabled="loading"
          color="default"
          @click="init"
      >
        <v-icon>
          mdi-refresh
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col
            cols="12"
        >
          <v-expansion-panels
              v-model="expansionPanelsFilter"
              :multiple="true"
          >
            <v-expansion-panel>
              <v-expansion-panel-header>
                <span>
                  <v-icon
                      color="info"
                  >
                    mdi-filter
                  </v-icon>

                  Filtre
                </span>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-row>
                  <v-col
                      cols="12"
                      lg="3"
                  >
                    <v-text-field
                        v-model="params.Filters.title"
                        :error-messages="errors.Filters && errors.Filters.title"
                        :outlined="true"
                        :dense="true"
                        hide-details="auto"
                        label="Názov"
                        :clearable="true"
                        @change="setFilter"
                    />
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>

      <v-row>
        <v-col
            cols="12"
        >
          <v-card>
            <v-card-text>
              <v-progress-linear
                  v-if="loading"
                  :indeterminate="true"
              />

              <v-simple-table>
                <template
                    v-slot:default
                >
                  <thead>
                    <tr>
                      <th
                          class="fixed"
                      >
                        Názov
                      </th>

                      <th>
                        <a
                            @click="setOrder($store.state.register.IndexGroup.Filter.order['sort'].value)"
                        >
                          <template
                              v-if="params.order === $store.state.register.IndexGroup.Filter.order['sort'].value"
                          >
                            <template
                                v-if="params.by === $store.state.register.IndexGroup.Filter.by['ASC'].value"
                            >
                              <v-icon>
                                mdi-chevron-up
                              </v-icon>
                            </template>

                            <template
                                v-else
                            >
                              <v-icon>
                                mdi-chevron-down
                              </v-icon>
                            </template>
                          </template>

                          Poradie
                        </a>
                      </th>

                      <th
                          class="text-right fixed"
                      />
                    </tr>
                  </thead>

                  <tbody>
                    <template
                        v-if="indexGroups.length > 0"
                    >
                      <tr
                          v-for="IndexGroup in indexGroups"
                          :key="IndexGroup.id"
                      >
                        <td
                            class="fixed"
                        >
                          <span
                              class="text-truncate"
                              style="max-width: 300px"
                          >
                            {{ IndexGroup.title }}
                          </span>
                        </td>

                        <td>
                          {{ IndexGroup.sort }}
                        </td>

                        <td
                            class="text-right fixed"
                        >
                          <v-btn
                              :icon="true"
                              :outlined="true"
                              color="primary"
                              :to="'/index-group/' + IndexGroup.id"
                          >
                            <v-icon>
                              mdi-magnify
                            </v-icon>
                          </v-btn>

                          &nbsp;

                          <Update
                              :icon="true"
                              :id="IndexGroup.id"
                              @init="init"
                          />

                          &nbsp;

                          <Delete
                              :icon="true"
                              :id="IndexGroup.id"
                              @init="init"
                          />
                        </td>
                      </tr>
                    </template>

                    <template
                        v-else
                    >
                      <tr>
                        <td
                            :colspan="3"
                            class="text-center"
                        >
                          Nenašli sa žiadne skupiny číselníkov.
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row
          v-if="pages !== 0"
          align="center"
      >
        <v-col
            cols="4"
        >
          <a
              v-for="(limit, i) in $store.getters['register/getIndexGroupFilterLimit']"
              :key="i"
              @click="setLimit(limit.value)"
          >
            {{ limit.text }}
          </a>
        </v-col>

        <v-spacer/>

        <v-col
            cols="auto"
        >
          <v-btn
              :icon="true"
              :outlined="true"
              :loading="loading"
              :disabled="params.page === 1 || loading"
              color="primary"
              @click="previousPage"
          >
            <v-icon>
              mdi-chevron-left
            </v-icon>
          </v-btn>
        </v-col>

        <v-col
            cols="auto"
        >
          {{ params.page }} / {{ pages }}
        </v-col>

        <v-col
            cols="auto"
        >
          <v-btn
              :icon="true"
              :outlined="true"
              :loading="loading"
              :disabled="params.page === pages || loading"
              color="primary"
              @click="nextPage"
          >
            <v-icon>
              mdi-chevron-right
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  import Qs from 'qs'
  import QueryTypes from 'query-types'

  import IndexGroups from '@/services/index-groups'

  import Create from '@/components/IndexGroups/Create'
  import Update from '@/components/IndexGroups/Update'
  import Delete from '@/components/IndexGroups/Delete'

  export default {
    name: 'IndexGroups',
    components: {
      Create,
      Update,
      Delete
    },
    data() {
      return {
        expansionPanelsFilter: [],
        loading: false,
        count: 0,
        pages: 1,
        indexGroups: [],
        params: {
          Filters: {
            title: ''
          },
          order: this.$store.state.register.IndexGroup.Filter.order['sort'].value,
          by: this.$store.state.register.IndexGroup.Filter.by['ASC'].value,
          limit: this.$store.state.register.IndexGroup.Filter.limit[25].value,
          page: 1
        },
        errors: {}
      }
    },
    metaInfo() {
      return {
        title: 'Skupiny číselníkov'
      }
    },
    beforeMount() {
      this.beforeInit()
    },
    mounted() {
      this.init()
    },
    methods: {
      beforeInit() {
        this.params = {
          ...this.params,
          ...Qs.parse(QueryTypes.parseObject(this.$route.query))
        }
      },
      init() {
        this.loadData()
      },
      setFilter() {
        this.params.page = 1

        this.init()
      },
      setOrder(order) {
        this.params.order = order

        switch (this.params.by) {
          case this.$store.state.register.IndexGroup.Filter.by['ASC'].value:
            this.params.by = this.$store.state.register.IndexGroup.Filter.by['DESC'].value

            break

          case this.$store.state.register.IndexGroup.Filter.by['DESC'].value:
            this.params.by = this.$store.state.register.IndexGroup.Filter.by['ASC'].value

            break
        }

        this.params.page = 1

        this.init()
      },
      setLimit(limit) {
        this.params.limit = limit

        this.params.page = 1

        this.init()
      },
      previousPage() {
        this.params.page -= 1

        window.scrollTo(0, 0)

        this.init()
      },
      nextPage() {
        this.params.page += 1

        window.scrollTo(0, 0)

        this.init()
      },
      setBreadcrumbs() {
        this.$store.dispatch('layout/setBreadcrumbs', {
          breadcrumbs: [
            {
              text: 'Skupiny číselníkov',
              to: '/index-groups'
            }
          ]
        })
      },
      loadData() {
        this.setBreadcrumbs()

        this.loading = true

        this.$router.replace('?' + Qs.stringify(this.params))

        IndexGroups
          .index({
            params: this.params
          })
          .then((response) => {
            this.count = response.data.data.count

            this.pages = response.data.data.pages

            this.indexGroups = response.data.data.indexGroups

            this.errors = {}
          })
          .catch((error) => {
            switch (error.response.status) {
              case 400:
                this.errors = error.response.data.errors

                break

              case 401:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                break
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
</script>
