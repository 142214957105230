<template>
  <v-btn
      :outlined="true"
      :loading="loading"
      :disabled="loading"
      color="warning"
      @click="init"
      v-bind="$attrs"
      v-on="$listeners"
  >
    <v-icon>
      mdi-pencil
    </v-icon>

    <slot/>

    <v-dialog
        v-if="Volunteer"
        v-model="dialog"
        :persistent="true"
        :scrollable="true"
        max-width="1000"
    >
      <v-card>
        <v-card-title>
          Upraviť dobrovoľníka

          <v-spacer/>

          <v-btn
              :icon="true"
              color="default"
              @click="closeDialog"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-form
              @submit.stop.prevent="submit"
          >
            <v-row>
              <v-col
                  cols="12"
              >
                <b>
                  1. Osobné údaje
                </b>
              </v-col>

              <v-col
                  cols="12"
                  lg="4"
              >
                <ImageFiles
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Fotografia"
                    :persistent-hint="true"
                    hint="Odporúčaný formát 1:1"
                    :File="VolunteerForm.Photo"
                    :errorsFile="errors.Photo"
                    @setFile="setPhoto"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="4"
              >
                <v-text-field
                    v-model="VolunteerForm.firstName"
                    :error-messages="errors.firstName"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Krstné meno"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="4"
              >
                <v-text-field
                    v-model="VolunteerForm.lastName"
                    :error-messages="errors.lastName"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Priezvisko"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="6"
              >
                <v-text-field
                    v-model="VolunteerForm.email"
                    :error-messages="errors.email"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="E-mail"
                    type="email"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="6"
              >
                <v-text-field
                    v-model="VolunteerForm.phone"
                    :error-messages="errors.phone"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Telefón"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="4"
              >
                <v-text-field
                    v-model="VolunteerForm.birthDate"
                    :error-messages="errors.birthDate"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Dátum narodenia"
                    type="date"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="4"
              >
                <v-select
                    v-model="VolunteerForm.gender"
                    :error-messages="errors.gender"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Pohlavie"
                    :items="$store.getters['register/getVolunteerGender']"
                    no-data-text="Žiadne výsledky"
                    :clearable="true"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="4"
              >
                <SuggestIndexes
                    v-model="VolunteerForm.shirtSize"
                    :error-messages="errors.shirtSize"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Veľkosť trička"
                    module="shirt_size"
                    :clearable="true"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="5"
              >
                <v-text-field
                    v-model="VolunteerForm.street"
                    :error-messages="errors.street"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Ulica"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="5"
              >
                <v-text-field
                    v-model="VolunteerForm.city"
                    :error-messages="errors.city"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Mesto"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="2"
              >
                <v-text-field
                    v-model="VolunteerForm.zip"
                    :error-messages="errors.zip"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="PSČ"
                />
              </v-col>

              <v-col
                  cols="12"
              >
                <SuggestIndexes
                    v-model="VolunteerForm.country"
                    :error-messages="errors.country"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Krajina"
                    module="country"
                    :clearable="true"
                />
              </v-col>

              <v-col
                  cols="12"
              >
                <v-checkbox
                    v-model="VolunteerForm.agreementNewsletter"
                    :error-messages="errors.agreementNewsletter"
                    :dense="true"
                    hide-details="auto"
                    label="Mám záujem o novinky"
                />
              </v-col>

              <v-col
                  cols="12"
              >
                <b>
                  2. Vzdelanie, schopnost a zručnosti
                </b>
              </v-col>

              <v-col
                  cols="12"
                  lg="6"
              >
                <SuggestIndexes
                    v-model="VolunteerForm.educationLevel"
                    :error-messages="errorMessages(errors.educationLevel)"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Dosiahnuté vzdelanie"
                    module="education_level"
                    :multiple="true"
                    :clearable="true"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="6"
              >
                <SuggestIndexes
                    v-model="VolunteerForm.fieldOfStudyOrWork"
                    :error-messages="errorMessages(errors.fieldOfStudyOrWork)"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Oblasť štúdia / práce"
                    module="field_of_study_or_work"
                    :multiple="true"
                    :clearable="true"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="6"
              >
                <SuggestIndexes
                    v-model="VolunteerForm.languageSkills"
                    :error-messages="errorMessages(errors.languageSkills)"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Jazykové schopnosti"
                    module="language_skills"
                    :multiple="true"
                    :clearable="true"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="6"
              >
                <SuggestIndexes
                    v-model="VolunteerForm.computerSkills"
                    :error-messages="errorMessages(errors.computerSkills)"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Počítačové schopnosti"
                    module="computer_skills"
                    :multiple="true"
                    :clearable="true"
                />
              </v-col>

              <v-col
                  cols="12"
              >
                <b>
                  3. Preferovaná oblasť záujmu / vzdelávania
                </b>
              </v-col>

              <v-col
                  cols="12"
                  lg="6"
              >
                <SuggestIndexes
                    v-model="VolunteerForm.fieldOfVolunteering"
                    :error-messages="errorMessages(errors.fieldOfVolunteering)"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Oblasť vykonávania dobrovoľníctva"
                    module="field_of_volunteering"
                    :multiple="true"
                    :clearable="true"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="6"
              >
                <SuggestIndexes
                    v-model="VolunteerForm.placeOfVolunteering"
                    :error-messages="errorMessages(errors.placeOfVolunteering)"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Miesto vykonávania dobrovoľníctva"
                    module="place_of_volunteering"
                    :multiple="true"
                    :clearable="true"
                />
              </v-col>

              <v-col
                  cols="12"
              >
                <v-checkbox
                    v-model="VolunteerForm.agreementEducationActivity"
                    :error-messages="errors.agreementEducationActivity"
                    :dense="true"
                    hide-details="auto"
                    label="Mám záujem o vzdelávacie aktivity pre dobrovoľníkov"
                />
              </v-col>

              <v-col
                  cols="12"
              >
                <v-checkbox
                    v-model="VolunteerForm.agreementPositionSupervisor"
                    :error-messages="errors.agreementPositionSupervisor"
                    :dense="true"
                    hide-details="auto"
                    label="Mám záujem o pozíciu supervízora pre dobrovoľníkov"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="6"
              >
                <v-textarea
                    v-model="VolunteerForm.strengths"
                    :error-messages="errors.strengths"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Silné stránky"
                    :rows="2"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="6"
              >
                <v-textarea
                    v-model="VolunteerForm.motivation"
                    :error-messages="errors.motivation"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Aká je tvoja motivácia"
                    :rows="2"
                />
              </v-col>

              <v-col
                  cols="12"
              >
                <SuggestIndexes
                    v-model="VolunteerForm.feedback"
                    :error-messages="errors.feedback"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Odkiaľ ste sa o nás dozvedeli"
                    module="feedback"
                    :clearable="true"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="6"
              >
                <v-textarea
                    v-model="VolunteerForm.notePrivate"
                    :error-messages="errors.notePrivate"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Súkromná poznámka"
                    :rows="2"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="6"
              >
                <v-textarea
                    v-model="VolunteerForm.notePrivatePersonal"
                    :error-messages="errors.notePrivatePersonal"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Súkromná poznámka - osobná"
                    :rows="2"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="6"
              >
                <v-text-field
                    v-model="VolunteerForm.bozp"
                    :error-messages="errors.bozp"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="BOZP"
                    type="date"
                    :clearable="true"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="6"
              >
                <v-text-field
                    v-model="VolunteerForm.opp"
                    :error-messages="errors.opp"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="OPP"
                    type="date"
                    :clearable="true"
                />
              </v-col>

              <v-col
                  cols="12"
              >
                <v-btn
                    type="submit"
                    :rounded="true"
                    :outlined="true"
                    :loading="loading"
                    :disabled="loading"
                    color="warning"
                >
                  <v-icon>
                    mdi-pencil
                  </v-icon>

                  Upraviť
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-btn>
</template>

<script>
  import { dateFormat } from '@vuejs-community/vue-filter-date-format'

  import Volunteers from '@/services/volunteers'

  import SuggestIndexes from '@/components/Indexes/Suggest'

  import ImageFiles from '@/components/Files/Image'

  export default {
    name: 'Update',
    props: [
      'id'
    ],
    components: {
      SuggestIndexes,
      ImageFiles
    },
    data() {
      return {
        loading: false,
        dialog: false,
        Volunteer: null,
        VolunteerForm: {},
        errors: {}
      }
    },
    methods: {
      init() {
        this.loadData()
      },
      closeDialog() {
        this.dialog = false
      },
      errorMessages(rows) {
        let errorMessages = []

        if (rows) {
          rows.map((row) => {
            if (row instanceof Array) {
              errorMessages = [...errorMessages, ...row]
            } else {
              errorMessages = [...errorMessages, row]
            }
          })
        }

        return errorMessages
      },
      loadData() {
        this.dialog = false

        this.loading = true

        Volunteers
          .read({
            id: this.id
          })
          .then((response) => {
            this.dialog = true

            this.Volunteer = response.data.data.Volunteer

            this.VolunteerForm = {
              Photo: (
                response.data.data.Volunteer.Photo
                  ? response.data.data.Volunteer.Photo.id
                  : null
              ),
              firstName: response.data.data.Volunteer.firstName,
              lastName: response.data.data.Volunteer.lastName,
              email: response.data.data.Volunteer.email,
              phone: response.data.data.Volunteer.phone,
              birthDate: dateFormat(new Date(response.data.data.Volunteer.birthDate), 'YYYY-MM-DD'),
              gender: response.data.data.Volunteer.gender,
              shirtSize: response.data.data.Volunteer.shirtSize,
              city: response.data.data.Volunteer.city,
              street: response.data.data.Volunteer.street,
              zip: response.data.data.Volunteer.zip,
              country: response.data.data.Volunteer.country,
              agreementNewsletter: response.data.data.Volunteer.agreementNewsletter,
              educationLevel: response.data.data.Volunteer.educationLevel,
              fieldOfStudyOrWork: response.data.data.Volunteer.fieldOfStudyOrWork,
              languageSkills: response.data.data.Volunteer.languageSkills,
              computerSkills: response.data.data.Volunteer.computerSkills,
              fieldOfVolunteering: response.data.data.Volunteer.fieldOfVolunteering,
              placeOfVolunteering: response.data.data.Volunteer.placeOfVolunteering,
              agreementEducationActivity: response.data.data.Volunteer.agreementEducationActivity,
              agreementPositionSupervisor: response.data.data.Volunteer.agreementPositionSupervisor,
              strengths: response.data.data.Volunteer.strengths,
              motivation: response.data.data.Volunteer.motivation,
              feedback: response.data.data.Volunteer.feedback,
              notePrivate: response.data.data.Volunteer.notePrivate,
              notePrivatePersonal: response.data.data.Volunteer.notePrivatePersonal,
              bozp: (
                response.data.data.Volunteer.bozp
                  ? dateFormat(new Date(response.data.data.Volunteer.bozp), 'YYYY-MM-DD')
                  : null
              ),
              opp: (
                response.data.data.Volunteer.opp
                  ? dateFormat(new Date(response.data.data.Volunteer.opp), 'YYYY-MM-DD')
                  : null
              )
            }

            this.errors = {}
          })
          .catch((error) => {
            switch (error.response.status) {
              case 401:
              case 404:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                this.closeDialog()

                break
            }
          })
          .finally(() => {
            this.loading = false
          })
      },
      setPhoto(Photo) {
        this.VolunteerForm.Photo = Photo
      },
      async submit() {
        this.loading = true

        await Volunteers
          .update({
            id: this.id,
            data: this.VolunteerForm
          })
          .then((response) => {
            this.$store.dispatch('layout/openSnackbar', {
              message: response.data.message
            })

            this.closeDialog()

            this.$emit('init')
          })
          .catch((error) => {
            switch (error.response.status) {
              case 400:
                this.errors = error.response.data.errors

                break

              case 401:
              case 404:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                this.closeDialog()

                break
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
</script>
