<template>
  <v-btn
      :outlined="true"
      :loading="loading"
      :disabled="loading"
      color="warning"
      @click="init"
      v-bind="$attrs"
      v-on="$listeners"
  >
    <v-icon>
      mdi-pencil
    </v-icon>

    <slot/>

    <v-dialog
        v-if="Filter"
        v-model="dialog"
        :persistent="true"
        :scrollable="true"
        max-width="500"
    >
      <v-card>
        <v-card-title>
          Upraviť filter

          <v-spacer/>

          <v-btn
              :icon="true"
              color="default"
              @click="closeDialog"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-form
              @submit.stop.prevent="submit"
          >
            <v-row>
              <v-col
                  cols="12"
              >
                <SuggestFilterGroups
                    v-model="FilterForm.FilterGroup"
                    :error-messages="errors.FilterGroup"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Skupina filtrov"
                    :createButton="true"
                />
              </v-col>

              <v-col
                  cols="12"
              >
                <v-text-field
                    v-model="FilterForm.title"
                    :error-messages="errors.title"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Názov"
                />
              </v-col>

              <v-col
                  cols="12"
              >
                <v-btn
                    type="submit"
                    :rounded="true"
                    :outlined="true"
                    :loading="loading"
                    :disabled="loading"
                    color="warning"
                >
                  <v-icon>
                    mdi-pencil
                  </v-icon>

                  Upraviť
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-btn>
</template>

<script>
  import Filters from '@/services/filters'

  import SuggestFilterGroups from '@/components/FilterGroups/Suggest'

  export default {
    name: 'Update',
    props: [
      'id'
    ],
    components: {
      SuggestFilterGroups
    },
    data() {
      return {
        loading: false,
        dialog: false,
        Filter: null,
        FilterForm: {},
        errors: {}
      }
    },
    methods: {
      init() {
        this.loadData()
      },
      closeDialog() {
        this.dialog = false
      },
      loadData() {
        this.dialog = false

        this.loading = true

        Filters
          .read({
            id: this.id
          })
          .then((response) => {
            this.dialog = true

            this.Filter = response.data.data.Filter

            this.FilterForm = {
              FilterGroup: response.data.data.Filter.FilterGroup.id,
              title: response.data.data.Filter.title
            }

            this.errors = {}
          })
          .catch((error) => {
            switch (error.response.status) {
              case 401:
              case 404:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                this.closeDialog()

                break
            }
          })
          .finally(() => {
            this.loading = false
          })
      },
      async submit() {
        this.loading = true

        await Filters
          .update({
            id: this.id,
            data: this.FilterForm
          })
          .then((response) => {
            this.$store.dispatch('layout/openSnackbar', {
              message: response.data.message
            })

            this.closeDialog()

            this.$emit('refresh')

            this.$emit('init')
          })
          .catch((error) => {
            switch (error.response.status) {
              case 400:
                this.errors = error.response.data.errors

                break

              case 401:
              case 404:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                this.closeDialog()

                break
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
</script>
