<template>
  <v-btn
      :outlined="true"
      :loading="loading"
      :disabled="loading"
      color="primary"
      @click="init"
      v-bind="$attrs"
      v-on="$listeners"
  >
    <v-icon>
      mdi-information
    </v-icon>

    <slot/>

    <v-dialog
        v-if="Application"
        v-model="dialog"
        :persistent="true"
        :scrollable="true"
        max-width="500"
    >
      <v-card>
        <v-card-title>
          Prihláška

          <v-spacer/>

          <v-btn
              :icon="true"
              color="default"
              @click="closeDialog"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <p>
            <b>
              Možnosti:
            </b>
          </p>

          <template
              v-if="sortOptionGroups.length > 0"
          >
            <ul
                v-for="OptionGroup in sortOptionGroups"
                :key="OptionGroup.id"
            >
              <li>
                {{ OptionGroup.title }}:

                <ul
                    v-if="OptionGroup.options.length > 0"
                >
                  <li
                      v-for="Option in OptionGroup.options"
                      :key="Option.id"
                  >
                    {{ Option.title }}
                  </li>
                </ul>
              </li>
            </ul>
          </template>

          <p
              v-else
          >
            Žiadne
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-btn>
</template>

<script>
  import Applications from '@/services/applications'

  export default {
    name: 'Preview',
    props: [
      'id'
    ],
    data() {
      return {
        loading: false,
        dialog: false,
        Application: null
      }
    },
    computed: {
      groupOptionGroups() {
        const groupedOptionGroups = {}

        this.Application.options.map((Option) => {
          if (!groupedOptionGroups[Option.OptionGroup.id]) {
            groupedOptionGroups[Option.OptionGroup.id] = {
              ...Option.OptionGroup,
              options: []
            }
          }

          groupedOptionGroups[Option.OptionGroup.id].options.push(Option)
        })

        return groupedOptionGroups
      },
      sortOptionGroups() {
        const sortedOptionGroups = [...Object.values(this.groupOptionGroups)]

        sortedOptionGroups.map((OptionGroup) => OptionGroup.options.sort((a, b) => a.sort - b.sort))

        sortedOptionGroups.sort((a, b) => a.sort - b.sort)

        return sortedOptionGroups
      }
    },
    methods: {
      init() {
        this.loadData()
      },
      closeDialog() {
        this.dialog = false
      },
      loadData() {
        this.dialog = false

        this.loading = true

        Applications
          .read({
            id: this.id
          })
          .then((response) => {
            this.dialog = true

            this.Application = response.data.data.Application
          })
          .catch((error) => {
            switch (error.response.status) {
              case 401:
              case 404:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                this.closeDialog()

                break
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
</script>
