<template>
  <v-btn
      :outlined="true"
      :loading="loading"
      :disabled="loading"
      color="error"
      @click="init"
      v-bind="$attrs"
      v-on="$listeners"
  >
    <v-icon>
      mdi-delete
    </v-icon>

    <slot/>

    <v-dialog
        v-if="IndexGroup"
        v-model="dialog"
        :persistent="true"
        :scrollable="true"
        max-width="500"
    >
      <v-card>
        <v-card-title>
          Odstrániť skupinu číselníkov

          <v-spacer/>

          <v-btn
              :icon="true"
              color="default"
              @click="closeDialog"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col
                cols="12"
            >
              <v-alert
                  type="info"
                  class="ma-0"
              >
                Naozaj chcete odstrániť skupinu číselníkov?
              </v-alert>
            </v-col>

            <v-col
                cols="12"
            >
              <v-btn
                  :rounded="true"
                  :outlined="true"
                  :loading="loading"
                  :disabled="loading"
                  color="error"
                  @click="submit"
              >
                <v-icon>
                  mdi-delete
                </v-icon>

                Áno
              </v-btn>

              &nbsp;

              <v-btn
                  :rounded="true"
                  :outlined="true"
                  color="default"
                  @click="closeDialog"
              >
                <v-icon>
                  mdi-close
                </v-icon>

                Nie
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-btn>
</template>

<script>
  import IndexGroups from '@/services/index-groups'

  export default {
    name: 'Delete',
    props: [
      'id'
    ],
    data() {
      return {
        loading: false,
        dialog: false,
        IndexGroup: null
      }
    },
    methods: {
      init() {
        this.loadData()
      },
      closeDialog() {
        this.dialog = false
      },
      loadData() {
        this.dialog = false

        this.loading = true

        IndexGroups
          .read({
            id: this.id
          })
          .then((response) => {
            this.dialog = true

            this.IndexGroup = response.data.data.IndexGroup
          })
          .catch((error) => {
            switch (error.response.status) {
              case 401:
              case 404:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                this.closeDialog()

                break
            }
          })
          .finally(() => {
            this.loading = false
          })
      },
      async submit() {
        this.loading = true

        await IndexGroups
          .delete({
            id: this.id
          })
          .then((response) => {
            this.$store.dispatch('layout/openSnackbar', {
              message: response.data.message
            })

            this.closeDialog()

            this.$emit('init')
          })
          .catch((error) => {
            switch (error.response.status) {
              case 401:
              case 404:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                this.closeDialog()

                break
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
</script>
