<template>
  <v-row
      v-if="optionGroups.length > 0"
  >
    <v-col
        cols="12"
        class="d-flex align-center"
    >
      <label
          class="v-label"
      >
        <b>
          Možnosti
        </b>
      </label>

      <v-spacer/>

      <v-btn
          :small="true"
          :icon="true"
          :outlined="true"
          :loading="loading"
          :disabled="loading"
          @click="init"
      >
        <v-icon>
          mdi-refresh
        </v-icon>
      </v-btn>
    </v-col>

    <v-col
        v-for="OptionGroup in optionGroups"
        :key="OptionGroup.id"
        cols="12"
    >
      <v-autocomplete
          :items="OptionGroup.options"
          item-value="id"
          item-text="title"
          no-data-text="Žiadne výsledky"
          :loading="loading"
          :disabled="loading"
          :label="OptionGroup.title"
          v-bind="$attrs"
          v-on="$listeners"
      >
        <template
            v-slot:append-outer
        >
          <CreateOption
              v-if="createButton"
              :icon="true"
              :small="true"
              :OptionGroup="OptionGroup"
              @init="init"
          />
        </template>
      </v-autocomplete>
    </v-col>
  </v-row>
</template>

<script>
  import OptionGroups from '@/services/option-groups'

  import CreateOption from '@/components/Options/Create'

  export default {
    name: 'SuggestOptions',
    props: [
      'createButton'
    ],
    components: {
      CreateOption
    },
    data() {
      return {
        loading: false,
        optionGroups: []
      }
    },
    mounted() {
      this.init()
    },
    methods: {
      init() {
        this.loadData()
      },
      loadData() {
        this.loading = true

        OptionGroups
          .suggest()
          .then((response) => {
            this.optionGroups = response.data.data.optionGroups
          })
          .catch((error) => {
            switch (error.response.status) {
              case 401:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                break
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
</script>
