<template>
  <v-btn
      :outlined="true"
      :loading="loading"
      :disabled="loading"
      color="warning"
      @click="init"
      v-bind="$attrs"
      v-on="$listeners"
  >
    <v-icon>
      mdi-pencil
    </v-icon>

    <slot/>

    <v-dialog
        v-if="Review"
        v-model="dialog"
        :persistent="true"
        :scrollable="true"
        max-width="750"
    >
      <v-card>
        <v-card-title>
          Upraviť recenziu

          <v-spacer/>

          <v-btn
              :icon="true"
              color="default"
              @click="closeDialog"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-form
              @submit.stop.prevent="submit"
          >
            <v-row>
              <v-col
                  cols="12"
                  lg="6"
              >
                <ImageFiles
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Obrázok"
                    :persistent-hint="true"
                    hint="Odporúčaný formát 1:1"
                    :File="ReviewForm.File"
                    :errorsFile="errors.File"
                    @setFile="setFile"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="6"
              >
                <v-text-field
                    v-model="ReviewForm.author"
                    :error-messages="errors.author"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Autor"
                />
              </v-col>

              <v-col
                  cols="12"
              >
                <v-textarea
                    v-model="ReviewForm.description"
                    :error-messages="errors.description"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Popis"
                    :rows="6"
                />
              </v-col>

              <v-col
                  cols="12"
              >
                <v-btn
                    type="submit"
                    :rounded="true"
                    :outlined="true"
                    :loading="loading"
                    :disabled="loading"
                    color="warning"
                >
                  <v-icon>
                    mdi-pencil
                  </v-icon>

                  Upraviť
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-btn>
</template>

<script>
  import Reviews from '@/services/reviews'

  import ImageFiles from '@/components/Files/Image'

  export default {
    name: 'Update',
    props: [
      'id'
    ],
    components: {
      ImageFiles
    },
    data() {
      return {
        loading: false,
        dialog: false,
        Review: null,
        ReviewForm: {},
        errors: {}
      }
    },
    methods: {
      init() {
        this.loadData()
      },
      closeDialog() {
        this.dialog = false
      },
      loadData() {
        this.dialog = false

        this.loading = true

        Reviews
          .read({
            id: this.id
          })
          .then((response) => {
            this.dialog = true

            this.Review = response.data.data.Review

            this.ReviewForm = {
              File: (
                response.data.data.Review.File
                  ? response.data.data.Review.File.id
                  : null
              ),
              author: response.data.data.Review.author,
              description: response.data.data.Review.description
            }

            this.errors = {}
          })
          .catch((error) => {
            switch (error.response.status) {
              case 401:
              case 404:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                this.closeDialog()

                break
            }
          })
          .finally(() => {
            this.loading = false
          })
      },
      setFile(File) {
        this.ReviewForm.File = File
      },
      async submit() {
        this.loading = true

        await Reviews
          .update({
            id: this.id,
            data: this.ReviewForm
          })
          .then((response) => {
            this.$store.dispatch('layout/openSnackbar', {
              message: response.data.message
            })

            this.closeDialog()

            this.$emit('init')
          })
          .catch((error) => {
            switch (error.response.status) {
              case 400:
                this.errors = error.response.data.errors

                break

              case 401:
              case 404:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                this.closeDialog()

                break
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
</script>
