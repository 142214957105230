import Vue from 'vue'

import Qs from 'qs'

class Files {
  async index({params}) {
    return await Vue.axios
      .get('app/files', {
        params,
        paramsSerializer: (params) => {
          return Qs.stringify(params)
        }
      })
  }

  async uploadImage({data}) {
    return await Vue.axios
      .post('files/upload/image', data)
  }

  async uploadPdf({data}) {
    return await Vue.axios
      .post('files/upload/pdf', data)
  }
}

const files = new Files()

export default files
