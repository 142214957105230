import Register from '@/services/register'

const state = {
  Admin: {
    type: {},
    Filter: {
      order: {},
      by: {},
      limit: {}
    }
  },
  Application: {
    status: {},
    Filter: {
      order: {},
      by: {},
      limit: {}
    }
  },
  Article: {
    Filter: {
      order: {},
      by: {},
      limit: {}
    }
  },
  Country: {
    Filter: {
      order: {},
      by: {},
      limit: {}
    }
  },
  Document: {
    type: {},
    Filter: {
      order: {},
      by: {},
      limit: {}
    }
  },
  Event: {
    action: {},
    type: {},
    Filter: {
      order: {},
      by: {},
      limit: {}
    }
  },
  File: {
    Filter: {
      order: {},
      by: {},
      limit: {}
    }
  },
  Filter: {
    Filter: {
      order: {},
      by: {},
      limit: {}
    }
  },
  FilterGroup: {
    Filter: {
      order: {},
      by: {},
      limit: {}
    }
  },
  Image: {
    Resource: {
      type: {}
    }
  },
  Index: {
    Filter: {
      order: {},
      by: {},
      limit: {}
    }
  },
  IndexGroup: {
    Filter: {
      order: {},
      by: {},
      limit: {}
    }
  },
  Option: {
    Filter: {
      order: {},
      by: {},
      limit: {}
    }
  },
  OptionGroup: {
    Filter: {
      order: {},
      by: {},
      limit: {}
    }
  },
  Organizer: {
    Filter: {
      order: {},
      by: {},
      limit: {}
    }
  },
  Pdf: {
    Resource: {
      type: {}
    }
  },
  Region: {
    Filter: {
      order: {},
      by: {},
      limit: {}
    }
  },
  Review: {
    Filter: {
      order: {},
      by: {},
      limit: {}
    }
  },
  Site: {
    Filter: {
      order: {},
      by: {},
      limit: {}
    }
  },
  Volunteer: {
    gender: {},
    Filter: {
      order: {},
      by: {},
      limit: {}
    }
  }
}

const mutations = {
  SET_ADMIN(state, Admin) {
    state.Admin = Admin
  },
  SET_APPLICATION(state, Application) {
    state.Application = Application
  },
  SET_ARTICLE(state, Article) {
    state.Article = Article
  },
  SET_COUNTRY(state, Country) {
    state.Country = Country
  },
  SET_DOCUMENT(state, Document) {
    state.Document = Document
  },
  SET_EVENT(state, Event) {
    state.Event = Event
  },
  SET_FILE(state, File) {
    state.File = File
  },
  SET_FILTER(state, Filter) {
    state.Filter = Filter
  },
  SET_FILTER_GROUP(state, FilterGroup) {
    state.FilterGroup = FilterGroup
  },
  SET_IMAGE(state, Image) {
    state.Image = Image
  },
  SET_INDEX(state, Index) {
    state.Index = Index
  },
  SET_INDEX_GROUP(state, IndexGroup) {
    state.IndexGroup = IndexGroup
  },
  SET_OPTION(state, Option) {
    state.Option = Option
  },
  SET_OPTION_GROUP(state, OptionGroup) {
    state.OptionGroup = OptionGroup
  },
  SET_ORGANIZER(state, Organizer) {
    state.Organizer = Organizer
  },
  SET_PDF(state, Pdf) {
    state.Pdf = Pdf
  },
  SET_REGION(state, Region) {
    state.Region = Region
  },
  SET_REVIEW(state, Review) {
    state.Review = Review
  },
  SET_SITE(state, Site) {
    state.Site = Site
  },
  SET_VOLUNTEER(state, Volunteer) {
    state.Volunteer = Volunteer
  }
}

const actions = {
  async callIndex({commit}) {
    return await Register
      .index()
      .then((response) => {
        commit('SET_ADMIN', response.data.data.Admin)

        commit('SET_APPLICATION', response.data.data.Application)

        commit('SET_ARTICLE', response.data.data.Article)

        commit('SET_COUNTRY', response.data.data.Country)

        commit('SET_DOCUMENT', response.data.data.Document)

        commit('SET_EVENT', response.data.data.Event)

        commit('SET_FILE', response.data.data.File)

        commit('SET_FILTER', response.data.data.Filter)

        commit('SET_FILTER_GROUP', response.data.data.FilterGroup)

        commit('SET_IMAGE', response.data.data.Image)

        commit('SET_INDEX', response.data.data.Index)

        commit('SET_INDEX_GROUP', response.data.data.IndexGroup)

        commit('SET_OPTION', response.data.data.Option)

        commit('SET_OPTION_GROUP', response.data.data.OptionGroup)

        commit('SET_ORGANIZER', response.data.data.Organizer)

        commit('SET_PDF', response.data.data.Pdf)

        commit('SET_REGION', response.data.data.Region)

        commit('SET_REVIEW', response.data.data.Review)

        commit('SET_SITE', response.data.data.Site)

        commit('SET_VOLUNTEER', response.data.data.Volunteer)
      })
      .catch(() => {
        commit('SET_ADMIN', {
          type: {},
          Filter: {
            order: {},
            by: {},
            limit: {}
          }
        })

        commit('SET_APPLICATION', {
          status: {},
          Filter: {
            order: {},
            by: {},
            limit: {}
          }
        })

        commit('SET_ARTICLE', {
          Filter: {
            order: {},
            by: {},
            limit: {}
          }
        })

        commit('SET_COUNTRY', {
          Filter: {
            order: {},
            by: {},
            limit: {}
          }
        })

        commit('SET_DOCUMENT', {
          type: {},
          Filter: {
            order: {},
            by: {},
            limit: {}
          }
        })

        commit('SET_EVENT', {
          action: {},
          type: {},
          Filter: {
            order: {},
            by: {},
            limit: {}
          }
        })

        commit('SET_FILE', {
          Filter: {
            order: {},
            by: {},
            limit: {}
          }
        })

        commit('SET_FILTER', {
          Filter: {
            order: {},
            by: {},
            limit: {}
          }
        })

        commit('SET_FILTER_GROUP', {
          Filter: {
            order: {},
            by: {},
            limit: {}
          }
        })

        commit('SET_IMAGE', {
          Resource: {
            type: {}
          }
        })

        commit('SET_INDEX', {
          Filter: {
            order: {},
            by: {},
            limit: {}
          }
        })

        commit('SET_INDEX_GROUP', {
          Filter: {
            order: {},
            by: {},
            limit: {}
          }
        })

        commit('SET_OPTION', {
          Filter: {
            order: {},
            by: {},
            limit: {}
          }
        })

        commit('SET_OPTION_GROUP', {
          Filter: {
            order: {},
            by: {},
            limit: {}
          }
        })

        commit('SET_ORGANIZER', {
          Filter: {
            order: {},
            by: {},
            limit: {}
          }
        })

        commit('SET_PDF', {
          Resource: {
            type: {}
          }
        })

        commit('SET_REGION', {
          Filter: {
            order: {},
            by: {},
            limit: {}
          }
        })

        commit('SET_REVIEW', {
          Filter: {
            order: {},
            by: {},
            limit: {}
          }
        })

        commit('SET_SITE', {
          Filter: {
            order: {},
            by: {},
            limit: {}
          }
        })

        commit('SET_VOLUNTEER', {
          gender: {},
          Filter: {
            order: {},
            by: {},
            limit: {}
          }
        })
      })
  }
}

const getters = {
  getAdminType(state) {
    return Object.values(state.Admin.type)
  },
  getAdminFilterOrder(state) {
    return Object.values(state.Admin.Filter.order)
  },
  getAdminFilterBy(state) {
    return Object.values(state.Admin.Filter.by)
  },
  getAdminFilterLimit(state) {
    return Object.values(state.Admin.Filter.limit)
  },
  getArticleFilterOrder(state) {
    return Object.values(state.Article.Filter.order)
  },
  getArticleFilterBy(state) {
    return Object.values(state.Article.Filter.by)
  },
  getArticleFilterLimit(state) {
    return Object.values(state.Article.Filter.limit)
  },
  getApplicationStatus(state) {
    return Object.values(state.Application.status)
  },
  getApplicationFilterOrder(state) {
    return Object.values(state.Application.Filter.order)
  },
  getApplicationFilterBy(state) {
    return Object.values(state.Application.Filter.by)
  },
  getApplicationFilterLimit(state) {
    return Object.values(state.Application.Filter.limit)
  },
  getCountryFilterOrder(state) {
    return Object.values(state.Country.Filter.order)
  },
  getCountryFilterBy(state) {
    return Object.values(state.Country.Filter.by)
  },
  getCountryFilterLimit(state) {
    return Object.values(state.Country.Filter.limit)
  },
  getDocumentType(state) {
    return Object.values(state.Document.type)
  },
  getDocumentFilterOrder(state) {
    return Object.values(state.Document.Filter.order)
  },
  getDocumentFilterBy(state) {
    return Object.values(state.Document.Filter.by)
  },
  getDocumentFilterLimit(state) {
    return Object.values(state.Document.Filter.limit)
  },
  getEventAction(state) {
    return Object.values(state.Event.action)
  },
  getEventType(state) {
    return Object.values(state.Event.type)
  },
  getEventFilterOrder(state) {
    return Object.values(state.Event.Filter.order)
  },
  getEventFilterBy(state) {
    return Object.values(state.Event.Filter.by)
  },
  getEventFilterLimit(state) {
    return Object.values(state.Event.Filter.limit)
  },
  getFileFilterOrder(state) {
    return Object.values(state.File.Filter.order)
  },
  getFileFilterBy(state) {
    return Object.values(state.File.Filter.by)
  },
  getFileFilterLimit(state) {
    return Object.values(state.File.Filter.limit)
  },
  getFilterFilterOrder(state) {
    return Object.values(state.Filter.Filter.order)
  },
  getFilterFilterBy(state) {
    return Object.values(state.Filter.Filter.by)
  },
  getFilterFilterLimit(state) {
    return Object.values(state.Filter.Filter.limit)
  },
  getFilterGroupFilterOrder(state) {
    return Object.values(state.FilterGroup.Filter.order)
  },
  getFilterGroupFilterBy(state) {
    return Object.values(state.FilterGroup.Filter.by)
  },
  getFilterGroupFilterLimit(state) {
    return Object.values(state.FilterGroup.Filter.limit)
  },
  getImageResourceType(state) {
    return Object.values(state.Image.Resource.type)
  },
  getIndexFilterOrder(state) {
    return Object.values(state.Index.Filter.order)
  },
  getIndexFilterBy(state) {
    return Object.values(state.Index.Filter.by)
  },
  getIndexFilterLimit(state) {
    return Object.values(state.Index.Filter.limit)
  },
  getIndexGroupFilterOrder(state) {
    return Object.values(state.IndexGroup.Filter.order)
  },
  getIndexGroupFilterBy(state) {
    return Object.values(state.IndexGroup.Filter.by)
  },
  getIndexGroupFilterLimit(state) {
    return Object.values(state.IndexGroup.Filter.limit)
  },
  getOptionFilterOrder(state) {
    return Object.values(state.Option.Filter.order)
  },
  getOptionFilterBy(state) {
    return Object.values(state.Option.Filter.by)
  },
  getOptionFilterLimit(state) {
    return Object.values(state.Option.Filter.limit)
  },
  getOptionGroupFilterOrder(state) {
    return Object.values(state.OptionGroup.Filter.order)
  },
  getOptionGroupFilterBy(state) {
    return Object.values(state.OptionGroup.Filter.by)
  },
  getOptionGroupFilterLimit(state) {
    return Object.values(state.OptionGroup.Filter.limit)
  },
  getOrganizerFilterOrder(state) {
    return Object.values(state.Organizer.Filter.order)
  },
  getOrganizerFilterBy(state) {
    return Object.values(state.Organizer.Filter.by)
  },
  getOrganizerFilterLimit(state) {
    return Object.values(state.Organizer.Filter.limit)
  },
  getPdfResourceType(state) {
    return Object.values(state.Pdf.Resource.type)
  },
  getRegionFilterOrder(state) {
    return Object.values(state.Region.Filter.order)
  },
  getRegionFilterBy(state) {
    return Object.values(state.Region.Filter.by)
  },
  getRegionFilterLimit(state) {
    return Object.values(state.Region.Filter.limit)
  },
  getReviewFilterOrder(state) {
    return Object.values(state.Review.Filter.order)
  },
  getReviewFilterBy(state) {
    return Object.values(state.Review.Filter.by)
  },
  getReviewFilterLimit(state) {
    return Object.values(state.Review.Filter.limit)
  },
  getSiteFilterOrder(state) {
    return Object.values(state.Site.Filter.order)
  },
  getSiteFilterBy(state) {
    return Object.values(state.Site.Filter.by)
  },
  getSiteFilterLimit(state) {
    return Object.values(state.Site.Filter.limit)
  },
  getVolunteerGender(state) {
    return Object.values(state.Volunteer.gender)
  },
  getVolunteerFilterOrder(state) {
    return Object.values(state.Volunteer.Filter.order)
  },
  getVolunteerFilterBy(state) {
    return Object.values(state.Volunteer.Filter.by)
  },
  getVolunteerFilterLimit(state) {
    return Object.values(state.Volunteer.Filter.limit)
  }
}

const register = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

export default register
