import Vue from 'vue'

import Qs from 'qs'

class Regions {
  async suggest() {
    return await Vue.axios
      .get('app/regions/suggest')
  }

  async indexCountry({countryId, params}) {
    return await Vue.axios
      .get('app/regions-by-country/' + countryId, {
        params,
        paramsSerializer: (params) => {
          return Qs.stringify(params)
        }
      })
  }

  async create({data}) {
    return await Vue.axios
      .post('app/regions', data)
  }

  async read({id}) {
    return await Vue.axios
      .get('app/regions/' + id)
  }

  async update({id, data}) {
    return await Vue.axios
      .put('app/regions/' + id, data)
  }

  async delete({id}) {
    return await Vue.axios
      .delete('app/regions/' + id)
  }
}

const regions = new Regions()

export default regions
