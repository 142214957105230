<template>
  <v-card
      v-if="content && OptionGroup"
  >
    <v-card-title>
      <span
          class="text-truncate"
          style="max-width: 900px"
      >
        {{ OptionGroup.title }}
      </span>

      <v-spacer/>

      <v-btn
          :icon="true"
          :outlined="true"
          color="primary"
          to="/option-groups"
      >
        <v-icon>
          mdi-reply
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col
            cols="12"
        >
          <Options
              :OptionGroup="OptionGroup"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>

  <v-progress-circular
      v-else
      :indeterminate="true"
  />
</template>

<script>
  import OptionGroups from '@/services/option-groups'

  import Options from '@/components/OptionGroup/Options'

  export default {
    name: 'OptionGroup',
    components: {
      Options
    },
    data() {
      return {
        content: false,
        loading: false,
        OptionGroup: null
      }
    },
    metaInfo() {
      return {
        title: (
          this.OptionGroup
            ? this.OptionGroup.title
            : ''
        )
      }
    },
    mounted() {
      this.init()
    },
    watch: {
      $route(to, from) {
        if (to.path !== from.path) {
          this.init()
        }
      }
    },
    methods: {
      init() {
        this.loadData()
      },
      refresh() {
        this.loadData('loading', true)
      },
      setBreadcrumbs() {
        this.$store.dispatch('layout/setBreadcrumbs', {
          breadcrumbs: [
            {
              text: 'Skupiny možností',
              to: '/option-groups'
            }
          ]
        })
      },
      loadData(key = 'content', value = false) {
        this[key] = value

        OptionGroups
          .read({
            id: this.$route.params.id
          })
          .then((response) => {
            this.setBreadcrumbs()

            this.$store.dispatch('layout/addBreadcrumbs', {
              breadcrumbs: [
                {
                  text: response.data.data.OptionGroup.title,
                  to: '/option-group/' + response.data.data.OptionGroup.id
                }
              ]
            })

            this.OptionGroup = response.data.data.OptionGroup
          })
          .catch((error) => {
            switch (error.response.status) {
              case 401:
              case 404:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                this.$router.replace('/option-groups')

                break
            }
          })
          .finally(() => {
            this[key] = !value
          })
      }
    }
  }
</script>
