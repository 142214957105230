<template>
  <v-card
      v-if="content && Event"
  >
    <v-card-title>
      <span
          class="text-truncate"
          style="max-width: 900px"
      >
        {{ Event.title }}
      </span>

      <v-spacer/>

      <v-btn
          :icon="true"
          :outlined="true"
          color="primary"
          to="/events"
      >
        <v-icon>
          mdi-reply
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col
            cols="12"
        >
          <Applications
              :Event="Event"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>

  <v-progress-circular
      v-else
      :indeterminate="true"
  />
</template>

<script>
  import Events from '@/services/events'

  import Applications from '@/components/Event/Applications'

  export default {
    name: 'Event',
    components: {
      Applications
    },
    data() {
      return {
        content: false,
        loading: false,
        Event: null
      }
    },
    metaInfo() {
      return {
        title: (
          this.Event
            ? this.Event.title
            : ''
        )
      }
    },
    mounted() {
      this.init()
    },
    watch: {
      $route(to, from) {
        if (to.path !== from.path) {
          this.init()
        }
      }
    },
    methods: {
      init() {
        this.loadData()
      },
      refresh() {
        this.loadData('loading', true)
      },
      setBreadcrumbs() {
        this.$store.dispatch('layout/setBreadcrumbs', {
          breadcrumbs: [
            {
              text: 'Udalosti',
              to: '/events'
            }
          ]
        })
      },
      loadData(key = 'content', value = false) {
        this[key] = value

        Events
          .read({
            id: this.$route.params.id
          })
          .then((response) => {
            this.setBreadcrumbs()

            this.$store.dispatch('layout/addBreadcrumbs', {
              breadcrumbs: [
                {
                  text: response.data.data.Event.title,
                  to: '/event/' + response.data.data.Event.id
                }
              ]
            })

            this.Event = response.data.data.Event
          })
          .catch((error) => {
            switch (error.response.status) {
              case 401:
              case 404:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                this.$router.replace('/events')

                break
            }
          })
          .finally(() => {
            this[key] = !value
          })
      }
    }
  }
</script>
