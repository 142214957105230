<template>
  <v-autocomplete
      :items="items"
      item-value="id"
      item-text="title"
      no-data-text="Žiadne výsledky"
      :loading="loading"
      :disabled="loading"
      v-bind="$attrs"
      v-on="$listeners"
  >
    <template
        v-slot:append-outer
    >
      <CreateOptionGroup
          v-if="createButton"
          :icon="true"
          :small="true"
          @init="init"
      />

      &nbsp;

      <v-btn
          :small="true"
          :icon="true"
          :outlined="true"
          :loading="loading"
          :disabled="loading"
          @click="init"
      >
        <v-icon>
          mdi-refresh
        </v-icon>
      </v-btn>
    </template>
  </v-autocomplete>
</template>

<script>
  import OptionGroups from '@/services/option-groups'

  import CreateOptionGroup from '@/components/OptionGroups/Create'

  export default {
    name: 'Suggest',
    props: [
      'support',
      'createButton'
    ],
    components: {
      CreateOptionGroup
    },
    data() {
      return {
        loading: false,
        optionGroups: []
      }
    },
    mounted() {
      this.init()
    },
    computed: {
      items() {
        return (
          typeof this.support === 'function'
            ? this.support(this.optionGroups)
            : this.optionGroups
        )
      }
    },
    methods: {
      init() {
        this.loadData()
      },
      loadData() {
        this.loading = true

        OptionGroups
          .suggest()
          .then((response) => {
            this.optionGroups = response.data.data.optionGroups
          })
          .catch((error) => {
            switch (error.response.status) {
              case 401:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                break
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
</script>
