import Vue from 'vue'

import Qs from 'qs'

class Volunteers {
  async suggest() {
    return await Vue.axios
      .get('app/volunteers/suggest')
  }

  async index({params}) {
    return await Vue.axios
      .get('app/volunteers', {
        params,
        paramsSerializer: (params) => {
          return Qs.stringify(params)
        }
      })
  }

  async create({data}) {
    return await Vue.axios
      .post('app/volunteers', data)
  }

  async read({id}) {
    return await Vue.axios
      .get('app/volunteers/' + id)
  }

  async update({id, data}) {
    return await Vue.axios
      .put('app/volunteers/' + id, data)
  }

  async delete({id}) {
    return await Vue.axios
      .delete('app/volunteers/' + id)
  }
}

const volunteers = new Volunteers()

export default volunteers
