<template>
  <v-btn
      :outlined="true"
      :loading="loading"
      :disabled="loading"
      color="warning"
      @click="init"
      v-bind="$attrs"
      v-on="$listeners"
  >
    <v-icon>
      mdi-pencil
    </v-icon>

    <slot/>

    <v-dialog
        v-if="Organizer"
        v-model="dialog"
        :persistent="true"
        :scrollable="true"
        max-width="1000"
    >
      <v-card>
        <v-card-title>
          Upraviť organizátora

          <v-spacer/>

          <v-btn
              :icon="true"
              color="default"
              @click="closeDialog"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-form
              @submit.stop.prevent="submit"
          >
            <v-row>
              <v-col
                  cols="12"
              >
                <b>
                  1. Údaje organizátora
                </b>
              </v-col>

              <v-col
                  cols="12"
                  lg="4"
              >
                <ImageFiles
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Logo"
                    :persistent-hint="true"
                    hint="Odporúčaný formát 1:1"
                    :File="OrganizerForm.Logo"
                    :errorsFile="errors.Logo"
                    @setFile="setLogo"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="4"
              >
                <v-text-field
                    v-model="OrganizerForm.billingName"
                    :error-messages="errors.billingName"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Spoločnosť"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="4"
              >
                <v-text-field
                    v-model="OrganizerForm.position"
                    :error-messages="errors.position"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Pozícia"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="3"
              >
                <v-text-field
                    v-model="OrganizerForm.website"
                    :error-messages="errors.website"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Webstránka"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="3"
              >
                <v-text-field
                    v-model="OrganizerForm.facebook"
                    :error-messages="errors.facebook"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Facebook"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="3"
              >
                <v-text-field
                    v-model="OrganizerForm.instagram"
                    :error-messages="errors.instagram"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Instagram"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="3"
              >
                <v-text-field
                    v-model="OrganizerForm.linkedin"
                    :error-messages="errors.linkedin"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Linkedin"
                />
              </v-col>

              <v-col
                  cols="12"
              >
                <b>
                  2. Osobné údaje
                </b>
              </v-col>

              <v-col
                  cols="12"
                  lg="4"
              >
                <ImageFiles
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Fotografia"
                    :persistent-hint="true"
                    hint="Odporúčaný formát 1:1"
                    :File="OrganizerForm.Photo"
                    :errorsFile="errors.Photo"
                    @setFile="setPhoto"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="4"
              >
                <v-text-field
                    v-model="OrganizerForm.firstName"
                    :error-messages="errors.firstName"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Krstné meno"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="4"
              >
                <v-text-field
                    v-model="OrganizerForm.lastName"
                    :error-messages="errors.lastName"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Priezvisko"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="6"
              >
                <v-text-field
                    v-model="OrganizerForm.email"
                    :error-messages="errors.email"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="E-mail"
                    type="email"
                />
              </v-col>

              <v-col
                  cols="12"
                  lg="6"
              >
                <v-text-field
                    v-model="OrganizerForm.phone"
                    :error-messages="errors.phone"
                    :filled="true"
                    :dense="true"
                    hide-details="auto"
                    label="Telefón"
                />
              </v-col>

              <v-col
                  cols="12"
              >
                <v-checkbox
                    v-model="OrganizerForm.agreementNewsletter"
                    :error-messages="errors.agreementNewsletter"
                    :dense="true"
                    hide-details="auto"
                    label="Mám záujem o novinky"
                />
              </v-col>

              <v-col
                  cols="12"
              >
                <v-btn
                    type="submit"
                    :rounded="true"
                    :outlined="true"
                    :loading="loading"
                    :disabled="loading"
                    color="warning"
                >
                  <v-icon>
                    mdi-pencil
                  </v-icon>

                  Upraviť
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-btn>
</template>

<script>
  import Organizers from '@/services/organizers'

  import ImageFiles from '@/components/Files/Image'

  export default {
    name: 'Update',
    props: [
      'id'
    ],
    components: {
      ImageFiles
    },
    data() {
      return {
        loading: false,
        dialog: false,
        Organizer: null,
        OrganizerForm: {},
        errors: {}
      }
    },
    methods: {
      init() {
        this.loadData()
      },
      closeDialog() {
        this.dialog = false
      },
      loadData() {
        this.dialog = false

        this.loading = true

        Organizers
          .read({
            id: this.id
          })
          .then((response) => {
            this.dialog = true

            this.Organizer = response.data.data.Organizer

            this.OrganizerForm = {
              Logo: (
                response.data.data.Organizer.Logo
                  ? response.data.data.Organizer.Logo.id
                  : null
              ),
              billingName: response.data.data.Organizer.billingName,
              Photo: (
                response.data.data.Organizer.Photo
                  ? response.data.data.Organizer.Photo.id
                  : null
              ),
              firstName: response.data.data.Organizer.firstName,
              lastName: response.data.data.Organizer.lastName,
              position: response.data.data.Organizer.position,
              website: response.data.data.Organizer.website,
              facebook: response.data.data.Organizer.facebook,
              instagram: response.data.data.Organizer.instagram,
              linkedin: response.data.data.Organizer.linkedin,
              email: response.data.data.Organizer.email,
              phone: response.data.data.Organizer.phone,
              agreementNewsletter: response.data.data.Organizer.agreementNewsletter
            }

            this.errors = {}
          })
          .catch((error) => {
            switch (error.response.status) {
              case 401:
              case 404:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                this.closeDialog()

                break
            }
          })
          .finally(() => {
            this.loading = false
          })
      },
      setLogo(Logo) {
        this.OrganizerForm.Logo = Logo
      },
      setPhoto(Photo) {
        this.OrganizerForm.Photo = Photo
      },
      async submit() {
        this.loading = true

        await Organizers
          .update({
            id: this.id,
            data: this.OrganizerForm
          })
          .then((response) => {
            this.$store.dispatch('layout/openSnackbar', {
              message: response.data.message
            })

            this.closeDialog()

            this.$emit('init')
          })
          .catch((error) => {
            switch (error.response.status) {
              case 400:
                this.errors = error.response.data.errors

                break

              case 401:
              case 404:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                this.closeDialog()

                break
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
</script>
