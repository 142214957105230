import Vue from 'vue'

import Qs from 'qs'

class Filters {
  async indexFilterGroup({filterGroupId, params}) {
    return await Vue.axios
      .get('app/filters-by-filter-group/' + filterGroupId, {
        params,
        paramsSerializer: (params) => {
          return Qs.stringify(params)
        }
      })
  }

  async create({data}) {
    return await Vue.axios
      .post('app/filters', data)
  }

  async read({id}) {
    return await Vue.axios
      .get('app/filters/' + id)
  }

  async update({id, data}) {
    return await Vue.axios
      .put('app/filters/' + id, data)
  }

  async delete({id}) {
    return await Vue.axios
      .delete('app/filters/' + id)
  }
}

const filters = new Filters()

export default filters
