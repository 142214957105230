import Vue from 'vue'

import Qs from 'qs'

class Reviews {
  async index({params}) {
    return await Vue.axios
      .get('app/reviews', {
        params,
        paramsSerializer: (params) => {
          return Qs.stringify(params)
        }
      })
  }

  async create({data}) {
    return await Vue.axios
      .post('app/reviews', data)
  }

  async read({id}) {
    return await Vue.axios
      .get('app/reviews/' + id)
  }

  async update({id, data}) {
    return await Vue.axios
      .put('app/reviews/' + id, data)
  }

  async delete({id}) {
    return await Vue.axios
      .delete('app/reviews/' + id)
  }
}

const reviews = new Reviews()

export default reviews
