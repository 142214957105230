import Vue from 'vue'

import VueRouter from 'vue-router'

import Session from '@/middleware/session'

import Home from '@/views/Home'
import Login from '@/views/Login'
import RequestNewPassword from '@/views/RequestNewPassword'
import SetNewPassword from '@/views/SetNewPassword'
import Error from '@/views/Error'
import Admins from '@/views/Admins'
import Organizers from '@/views/Organizers'
import Volunteers from '@/views/Volunteers'
import Volunteer from '@/views/Volunteer'
import Events from '@/views/Events'
import Event from '@/views/Event'
import Countries from '@/views/Countries'
import Country from '@/views/Country'
import FilterGroups from '@/views/FilterGroups'
import FilterGroup from '@/views/FilterGroup'
import OptionGroups from '@/views/OptionGroups'
import OptionGroup from '@/views/OptionGroup'
import IndexGroups from '@/views/IndexGroups'
import IndexGroup from '@/views/IndexGroup'
import Sites from '@/views/Sites'
import Articles from '@/views/Articles'
import Reviews from '@/views/Reviews'
import Files from '@/views/Files'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '*',
    redirect: '/error'
  },
  {
    path: '/home',
    component: Home,
    meta: {
      type: 'none',
    },
    beforeEnter: Session
  },
  {
    path: '/login',
    component: Login,
    meta: {
      type: 'unlogged',
      auth: {
        isGranted: false
      }
    },
    beforeEnter: Session
  },
  {
    path: '/request-new-password',
    component: RequestNewPassword,
    meta: {
      type: 'unlogged',
      auth: {
        isGranted: false
      }
    },
    beforeEnter: Session
  },
  {
    path: '/set-new-password/:newPasswordToken',
    component: SetNewPassword,
    meta: {
      type: 'unlogged',
      auth: {
        isGranted: false
      }
    },
    beforeEnter: Session
  },
  {
    path: '/error',
    component: Error,
    meta: {
      type: 'none',
    },
    beforeEnter: Session
  },
  {
    path: '/admins',
    component: Admins,
    meta: {
      type: 'logged',
      auth: {
        isGranted: true,
        User: {
          roles: [
            'ROLE_MASTER'
          ]
        }
      }
    },
    beforeEnter: Session
  },
  {
    path: '/organizers',
    component: Organizers,
    meta: {
      type: 'logged',
      auth: {
        isGranted: true,
        User: {
          roles: [
            'ROLE_MASTER'
          ]
        }
      }
    },
    beforeEnter: Session
  },
  {
    path: '/volunteers',
    component: Volunteers,
    meta: {
      type: 'logged',
      auth: {
        isGranted: true,
        User: {
          roles: [
            'ROLE_MASTER'
          ]
        }
      }
    },
    beforeEnter: Session
  },
  {
    path: '/volunteer/:id',
    component: Volunteer,
    meta: {
      type: 'logged',
      auth: {
        isGranted: true,
        User: {
          roles: [
            'ROLE_MASTER'
          ]
        }
      }
    },
    beforeEnter: Session
  },
  {
    path: '/events',
    component: Events,
    meta: {
      type: 'logged',
      auth: {
        isGranted: true,
        User: {
          roles: [
            'ROLE_MASTER'
          ]
        }
      }
    },
    beforeEnter: Session
  },
  {
    path: '/event/:id',
    component: Event,
    meta: {
      type: 'logged',
      auth: {
        isGranted: true,
        User: {
          roles: [
            'ROLE_MASTER'
          ]
        }
      }
    },
    beforeEnter: Session
  },
  {
    path: '/countries',
    component: Countries,
    meta: {
      type: 'logged',
      auth: {
        isGranted: true,
        User: {
          roles: [
            'ROLE_MASTER'
          ]
        }
      }
    },
    beforeEnter: Session
  },
  {
    path: '/country/:id',
    component: Country,
    meta: {
      type: 'logged',
      auth: {
        isGranted: true,
        User: {
          roles: [
            'ROLE_MASTER'
          ]
        }
      }
    },
    beforeEnter: Session
  },
  {
    path: '/filter-groups',
    component: FilterGroups,
    meta: {
      type: 'logged',
      auth: {
        isGranted: true,
        User: {
          roles: [
            'ROLE_MASTER'
          ]
        }
      }
    },
    beforeEnter: Session
  },
  {
    path: '/filter-group/:id',
    component: FilterGroup,
    meta: {
      type: 'logged',
      auth: {
        isGranted: true,
        User: {
          roles: [
            'ROLE_MASTER'
          ]
        }
      }
    },
    beforeEnter: Session
  },
  {
    path: '/option-groups',
    component: OptionGroups,
    meta: {
      type: 'logged',
      auth: {
        isGranted: true,
        User: {
          roles: [
            'ROLE_MASTER'
          ]
        }
      }
    },
    beforeEnter: Session
  },
  {
    path: '/option-group/:id',
    component: OptionGroup,
    meta: {
      type: 'logged',
      auth: {
        isGranted: true,
        User: {
          roles: [
            'ROLE_MASTER'
          ]
        }
      }
    },
    beforeEnter: Session
  },
  {
    path: '/index-groups',
    component: IndexGroups,
    meta: {
      type: 'logged',
      auth: {
        isGranted: true,
        User: {
          roles: [
            'ROLE_MASTER'
          ]
        }
      }
    },
    beforeEnter: Session
  },
  {
    path: '/index-group/:id',
    component: IndexGroup,
    meta: {
      type: 'logged',
      auth: {
        isGranted: true,
        User: {
          roles: [
            'ROLE_MASTER'
          ]
        }
      }
    },
    beforeEnter: Session
  },
  {
    path: '/sites',
    component: Sites,
    meta: {
      type: 'logged',
      auth: {
        isGranted: true,
        User: {
          roles: [
            'ROLE_MASTER'
          ]
        }
      }
    },
    beforeEnter: Session
  },
  {
    path: '/articles',
    component: Articles,
    meta: {
      type: 'logged',
      auth: {
        isGranted: true,
        User: {
          roles: [
            'ROLE_MASTER'
          ]
        }
      }
    },
    beforeEnter: Session
  },
  {
    path: '/reviews',
    component: Reviews,
    meta: {
      type: 'logged',
      auth: {
        isGranted: true,
        User: {
          roles: [
            'ROLE_MASTER'
          ]
        }
      }
    },
    beforeEnter: Session
  },
  {
    path: '/files',
    component: Files,
    meta: {
      type: 'logged',
      auth: {
        isGranted: true,
        User: {
          roles: [
            'ROLE_MASTER'
          ]
        }
      }
    },
    beforeEnter: Session
  }
]

const options = {
  mode: 'history',
  base: process.env.VUE_APP_PUBLIC_PATH,
  routes: routes
}

const router = new VueRouter(options)

export default router
